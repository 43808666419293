import { createFileRoute } from '@tanstack/react-router';

import { PageLayout } from '@/components';

import { ProductTypeExtra, ProductTypesTable } from './-components';

export const Route = createFileRoute('/_authenticated/billing/product-types/')({
  component: ProductTypes,
});

function ProductTypes() {
  return (
    <PageLayout title="Product Types" extra={<ProductTypeExtra />}>
      <ProductTypesTable />
    </PageLayout>
  );
}
