import { createFileRoute } from '@tanstack/react-router';
import { Tabs } from 'antd';
import { fallback, literal, number, object, optional, parse, string, union } from 'valibot';

import { PageLayout } from '@/components';

import { ProspectLogTable, type ProspectLogTableFilters } from './-components/ProspectLogTable';
import { useProspectLogsViewModel } from './-hooks/useProspectLogsViewModel';

const SearchSchema = object({
  page: fallback(optional(number(), 1), 1),
  view: fallback(union([literal('all'), literal('no_data'), literal('wrong_data')]), 'all'),
  from_dt: fallback(optional(number()), undefined),
  to_dt: fallback(optional(number()), undefined),
  plan_type: fallback(optional(string()), undefined),
  domain: fallback(optional(string()), undefined),
  data_filters: fallback(optional(string()), undefined),
});

export const Route = createFileRoute('/_authenticated/prospect-logs/')({
  component: ProspectLogs,
  validateSearch(search: ProspectLogTableFilters) {
    return parse(SearchSchema, search);
  },
});

function ProspectLogs() {
  const { searchParams, onFilterChange, onTabChange } = useProspectLogsViewModel();

  return (
    <PageLayout title="Prospect logs">
      <Tabs
        defaultActiveKey="all"
        activeKey={searchParams.view}
        items={[
          {
            key: 'all',
            label: 'All',
            children: <ProspectLogTable onFilterChange={onFilterChange} filters={searchParams} />,
          },
          {
            key: 'no_data',
            label: 'No result',
            children: <ProspectLogTable onFilterChange={onFilterChange} filters={searchParams} />,
          },
        ]}
        onChange={onTabChange}
      />
    </PageLayout>
  );
}
