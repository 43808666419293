import { FC } from 'react';
import { Modal } from 'antd';

import { ActionsCell, JsonModal } from '@/components';

import type { Purchase } from '@/features/billing';

import { usePurchaseActionsViewModel } from './usePurchaseActionsViewModel';

export const PurchaseActions: FC<{ item: Purchase }> = ({ item }) => {
  const {
    items,
    visibleJsonViwer,
    visibleDeleteGift,
    isDeleteLoading,
    setVisibleDeleteGift,
    setVisibleJsonViwer,
    onDeleteGift,
  } = usePurchaseActionsViewModel(item);

  return (
    <>
      <ActionsCell items={items} />
      <JsonModal json={item} isOpened={visibleJsonViwer} onClose={() => setVisibleJsonViwer(false)} />
      <Modal
        open={visibleDeleteGift}
        title={`Delete gift ${item.product_label} for ${item.account_email}?`}
        okButtonProps={{ danger: true, loading: isDeleteLoading }}
        onCancel={() => setVisibleDeleteGift(false)}
        onOk={onDeleteGift}
      />
    </>
  );
};
