import { useState } from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useNavigate } from '@tanstack/react-router';
import { App, Modal } from 'antd';
import type { ItemType } from 'antd/es/menu/interface';

import { type Product, useDeleteProduct } from '@/features/billing';

export const useProductActionsViewModel = ({ product }: { product: Product }) => {
  const { message } = App.useApp();
  const [isJsonModalOpened, setIsJsonModalOpened] = useState(false);
  const navigate = useNavigate();
  const deleteProduct = useDeleteProduct();

  const items: ItemType[] = [
    {
      key: 'details',
      label: 'Details',
      onClick: () => setIsJsonModalOpened(true),
    },
    {
      key: 'edit',
      label: 'Edit',
      onClick: () =>
        navigate({
          to: '/billing/products/$productId',
          params: {
            productId: product.id,
          },
        }),
    },
    {
      key: 'delete',
      label: 'Delete',
      danger: true,
      onClick: () => {
        Modal.confirm({
          title: `Delete product "${product.label}"?`,
          icon: <ExclamationCircleFilled />,
          onOk: async () => {
            try {
              await deleteProduct.mutateAsync(product.id);
              message.success(`Product "${product.label}" was successfully deleted`);
            } catch (e) {
              message.error(`Failed to delete product "${product.label}"`);
            }
          },
        });
      },
    },
  ];

  const closeJsonModal = () => setIsJsonModalOpened(false);

  return {
    isJsonModalOpened,
    closeJsonModal,
    items,
  };
};
