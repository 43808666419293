import { getRouteApi, useNavigate } from '@tanstack/react-router';

import { ProspectLogTableFilters } from '../-components/ProspectLogTable';

const routeApi = getRouteApi('/_authenticated/prospect-logs/');

export const useProspectLogsViewModel = () => {
  const searchParams = routeApi.useSearch();
  const navigate = useNavigate();

  const onTabChange = (key: string) => {
    navigate({ search: { page: 1, view: key as ProspectLogTableFilters['view'] } });
  };

  const onFilterChange = (filters: ProspectLogTableFilters) => {
    navigate({ search: filters });
  };

  return {
    searchParams,
    onTabChange,
    onFilterChange,
  };
};
