import { createFileRoute } from '@tanstack/react-router';
import { fallback, literal, number, object, optional, parse, string, union } from 'valibot';

import { PageLayout } from '@/components';

import { type WorkspaceFilters, WorkspacesTable } from './-components/WorkspacesTable';

const FiltersSchema = object({
  length: fallback(number(), 20),
  page: fallback(optional(number(), 1), 1),
  start: fallback(optional(number(), 0), 0),
  sort_dir: fallback(optional(union([literal('desc'), literal('asc')]), 'desc'), 'desc'),
  sort_by: optional(string()),
  search: optional(string()),
});

export const Route = createFileRoute('/_authenticated/workspaces/')({
  component: Workspaces,
  validateSearch: (search): WorkspaceFilters => parse(FiltersSchema, search),
});

function Workspaces() {
  const searchParams = Route.useSearch();
  const navigate = Route.useNavigate();

  const onParamsChange = (params: WorkspaceFilters) => {
    navigate({ search: params });
  };

  return (
    <PageLayout title="Workspaces">
      <WorkspacesTable filters={searchParams} onFiltersChange={onParamsChange} />
    </PageLayout>
  );
}
