import { SortOrder } from 'antd/es/table/interface';

export type SortOrderApi = 'asc' | 'desc' | null;

export class SortOrderMapper {
  static toUi(sortOrderApi: SortOrderApi): SortOrder {
    switch (sortOrderApi) {
      case 'asc':
        return 'ascend';
      case 'desc':
        return 'descend';
      default:
        return null;
    }
  }

  static toApi(sortOrder: SortOrder): SortOrderApi {
    switch (sortOrder) {
      case 'ascend':
        return 'asc';
      case 'descend':
        return 'desc';
      default:
        return null;
    }
  }
}
