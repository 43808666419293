import { createFileRoute, Navigate, Outlet, redirect } from '@tanstack/react-router';

import { useAuth } from '@/features/auth';

export const Route = createFileRoute('/_unauthenticated')({
  beforeLoad: async ({ context }) => {
    if (context.auth.isAuthenticated) {
      throw redirect({ to: '/' });
    }
  },
  component: UnauthenticatedLayout,
});

function UnauthenticatedLayout() {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
}
