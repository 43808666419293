import React from 'react';
import { useNavigate } from '@tanstack/react-router';
import { Button, Card, Flex, Input, Space, Table } from 'antd';

import { DateCell, ErrorLayout } from '@/components';

import { SortOrderApi } from '@/libs';

import { PowerleadWorkspace } from '@/features/powerlead/workspace';

import { TableActionsCell } from './components/TableActionsCell';
import { WorkspaceTeamMembersTable } from './components/WorkspaceTeamMembersTable';
import { useWorkspacesTableViewModel } from './hooks/useWorkspacesTableViewModel';

import styles from './styles.module.scss';

export type WorkspaceFilters = {
  length?: number;
  page?: number;
  start?: number;
  sort_by?: string;
  sort_dir?: SortOrderApi;
  search?: string;
};

type Props = {
  filters: WorkspaceFilters;
  onFiltersChange(params: WorkspaceFilters): void;
};

const WorkspaceColumn = Table.Column<PowerleadWorkspace>;

export const WorkspacesTable: React.FC<Props> = ({ filters, onFiltersChange }) => {
  const navigate = useNavigate();
  const { pagination, isLoading, isError, dataSource, onSearch, onTableChange } = useWorkspacesTableViewModel({
    filters,
    onFiltersChange,
  });

  if (isError) return <ErrorLayout />;

  return (
    <Card>
      <Flex className={styles.wrapper} vertical>
        <Space direction="horizontal" key="filter">
          <Input.Search
            className={styles.search}
            placeholder="Search email, name, workspace id"
            allowClear
            enterButton="Search"
            key="input"
            defaultValue={filters.search}
            onSearch={onSearch}
          />
        </Space>
        <Table
          dataSource={dataSource}
          bordered
          scroll={{ x: 'max-content' }}
          pagination={{ ...pagination, position: ['topLeft', 'bottomLeft'] }}
          loading={isLoading}
          expandable={{
            expandedRowRender: (record) => <WorkspaceTeamMembersTable data={record.members} />,
          }}
          rowKey={(record) => record.uuid}
          onChange={onTableChange}
        >
          <WorkspaceColumn
            dataIndex="uuid"
            key="uuid"
            title="ID"
            width={320}
            render={(_, item) => (
              <Button
                onClick={() => {
                  navigate({ to: '/accounts', search: { workspace_id: item.uuid } });
                }}
                type="link"
              >
                {item.uuid}
              </Button>
            )}
          />
          <WorkspaceColumn dataIndex="owner_full_name" key="full_name" title="Full name" sorter />
          <WorkspaceColumn dataIndex="owner_email" key="email" title="Email" width="250px" sorter />
          <WorkspaceColumn
            dataIndex="created_at"
            key="created_at"
            title="Create date"
            sorter
            render={(date) => <DateCell date={date} />}
          />
          <WorkspaceColumn dataIndex={['subscription', 'plan']} key="plan" title="Plan" sorter />
          <WorkspaceColumn dataIndex="members_count" key="team_members" title="Team members" width="170px" sorter />
          <WorkspaceColumn
            key="searches-credits"
            title="Searches/Credits"
            sorter
            render={(_, item) => `${item.current_search_count}/${item.current_credits_used}`}
          />
          <WorkspaceColumn
            key="actions"
            title="Actions"
            render={(_, item) => <TableActionsCell item={item} searchParams={filters} />}
          />
        </Table>
      </Flex>
    </Card>
  );
};
