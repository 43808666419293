import { FC } from 'react';
import { Button, Card, Col, DatePicker, Input, Row, Select, Space, Table } from 'antd';

import { JsonModal } from '@/components';

import { PowerleadProspectLog } from '@/features/powerlead/prospectLog';

import { useProspectLogTable } from './hooks/useProspectLogTable';
import { ProspectLogTableFilters } from './types';

import styles from './styles.module.scss';

const QuotaColumn = Table.Column<PowerleadProspectLog>;

type ProspectLogTableProps = {
  filters: ProspectLogTableFilters;
  onFilterChange: (filters: ProspectLogTableFilters) => void;
};

const { RangePicker } = DatePicker;

export const ProspectLogTable: FC<ProspectLogTableProps> = (props) => {
  const {
    dataSource,
    pagination,
    planTypeOptions,
    filterTypeOptions,
    onResetFilters,
    values,
    activeItem,
    onChangeRangePicker,
    onChangePlanType,
    onChangeFilterType,
    onChangeDomain,
    isLoading,
    isError,
    onTableChange,
  } = useProspectLogTable(props);

  return (
    <Card>
      <Space direction="vertical" className={styles.space}>
        <Row className={styles.row}>
          <Col span={3}>
            <RangePicker
              className={styles.select}
              showTime={false}
              value={values.fromTo}
              name="fromTo"
              onChange={onChangeRangePicker}
            />
          </Col>
          <Col span={3}>
            <Select
              className={styles.select}
              showSearch
              placeholder="Plan type"
              optionFilterProp="label"
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={planTypeOptions}
              value={values.planType || undefined}
              onChange={onChangePlanType}
            />
          </Col>
          <Col span={3}>
            <Select
              className={styles.select}
              showSearch
              placeholder="Filter type"
              optionFilterProp="label"
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
              mode="multiple"
              options={filterTypeOptions}
              value={values.dataFilters || undefined}
              onChange={onChangeFilterType}
            />
          </Col>
          <Col span={3}>
            <Input.Search
              placeholder="Company domain"
              defaultValue={values.domain}
              allowClear
              onSearch={onChangeDomain}
            />
          </Col>
          <Col>
            <Button type="primary" onClick={onResetFilters}>
              Reset filters
            </Button>
          </Col>
        </Row>
        <Table
          dataSource={dataSource}
          pagination={{ ...pagination, position: ['topLeft', 'bottomLeft'] }}
          loading={isLoading || isError}
          key="table"
          bordered
          scroll={{ x: 'max-content' }}
          onChange={onTableChange}
        >
          <QuotaColumn
            title="Enrichment name"
            dataIndex="enrichment_name"
            key="enrichment_name"
            render={(_, row) => {
              return row?.enrichment_data?.name ?? '-';
            }}
          />
          <QuotaColumn
            title="Enrichment LinkedinID"
            dataIndex="enrichment_data.linkedin_id"
            key="enrichment_data.linkedin_id"
            render={(_, row) => {
              return row?.enrichment_data?.linkedin_id ?? '-';
            }}
          />
          <QuotaColumn title="WorkspaceID" dataIndex="workspace_id" key="workspace_id" />
          <QuotaColumn title="AccountID" dataIndex="account_id" key="account_id" />
          <QuotaColumn title="Plan" dataIndex="account_plan_type" key="account_plan_type" />
          <QuotaColumn title="Domain" dataIndex="account_domain" key="account_domain" />
          <QuotaColumn title="Credits" dataIndex="credits_count" key="credits_count" />
          <QuotaColumn
            dataIndex="actions"
            key="actions"
            render={(_, row) => {
              return (
                <>
                  <Button
                    type="primary"
                    onClick={() => {
                      activeItem[1](row.uuid);
                    }}
                  >
                    Show detaile
                  </Button>
                  <JsonModal
                    json={row}
                    isOpened={activeItem?.[0] === row.uuid}
                    onClose={() => {
                      activeItem[1](null);
                    }}
                  />
                </>
              );
            }}
          />
        </Table>
      </Space>
    </Card>
  );
};

export { type ProspectLogTableFilters };
