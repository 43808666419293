import { FC } from 'react';
import { Typography } from 'antd';

type BooleanCellProps = {
  value: boolean;
};

export const BooleanCell: FC<BooleanCellProps> = ({ value }) => {
  return <Typography.Text>{value ? 'Yes' : 'No'}</Typography.Text>;
};
