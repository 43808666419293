import * as React from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { httpClient } from '@/libs/HttpClient';

import { authTokenManager } from '../libs';
import { AuthTokens, CurrentUser } from '../types';

export interface AuthContext {
  isAuthenticated: boolean;
  currentUser: CurrentUser | undefined;
  isLoading: boolean;
  isError: boolean;
  signIn: (params: { username: string; password: string }) => Promise<void>;
  signOut: () => Promise<void>;
}

export const AuthContext = React.createContext<AuthContext>({
  isAuthenticated: false,
  currentUser: undefined,
  isLoading: false,
  isError: false,
  signIn: async () => {},
  signOut: async () => {},
});

const getCurrentUser = async (): Promise<CurrentUser> => {
  const response = await httpClient.get<CurrentUser>('/current_account/');
  return response.data;
};

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const queryClient = useQueryClient();
  const [authTokens, setAuthTokens] = React.useState<AuthTokens | null>(() => authTokenManager.getAuthTokens());

  const {
    data: currentUser,
    isLoading,
    isError,
  } = useQuery<CurrentUser, Error>({
    queryFn: getCurrentUser,
    queryKey: ['account'],
    retry: false,
    enabled: !!authTokens?.access,
  });

  const isAuthenticated = !!authTokens;

  const signOut = async () => {
    authTokenManager.removeAuthTokens();
    setAuthTokens(null);
    queryClient.clear();
  };

  const signIn: AuthContext['signIn'] = async (params) => {
    const { data: newAuthTokens } = await httpClient.post<AuthTokens>('/token/', params);
    authTokenManager.setAuthTokens(newAuthTokens);
    setAuthTokens(newAuthTokens);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        currentUser,
        signIn,
        signOut,
        isLoading,
        isError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
