import { createFileRoute } from '@tanstack/react-router';
import { Card, Col, Row, Select, Spin, Statistic } from 'antd';
import dayjs from 'dayjs';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { fallback, literal, object, parse, union } from 'valibot';

import { PageLayout } from '@/components';

import { useBillingDashboardViewModel } from './-hooks/useBillingDashboardViewModel';

import styles from './styles.module.scss';

const SearchSchema = object({
  period: fallback(union([literal('week'), literal('month'), literal('year')]), 'week'),
});

export const Route = createFileRoute('/_authenticated/billing/dashboard/')({
  component: BillingDashboard,
  validateSearch: (search) => parse(SearchSchema, search),
});

function BillingDashboard() {
  const { isLoading, isError, graphData, statisticItems, periodOptions, currentPeriod, onChangePeriod } =
    useBillingDashboardViewModel();

  return (
    <PageLayout title="Billing dashboard">
      <Card
        title="Period:"
        extra={
          <Select
            options={periodOptions}
            defaultValue={currentPeriod}
            className={styles.select}
            onChange={onChangePeriod}
          />
        }
      >
        <Spin spinning={isError || isLoading}>
          <Row>
            <Col span={12}>
              <Row>
                {statisticItems.map((item) => (
                  <Col span={12} key={item.value}>
                    <Statistic title={item.label} value={item.value} loading={isLoading} />
                  </Col>
                ))}
              </Row>
            </Col>
            <Col span={12}>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={graphData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="at"
                    interval="preserveStartEnd"
                    tickFormatter={(x) => {
                      return dayjs(x).format('YYYY-MM-DD');
                    }}
                  />
                  <YAxis />
                  <Tooltip
                    labelFormatter={(x) => {
                      return dayjs(x).format('YYYY-MM-DD');
                    }}
                  />
                  <Area
                    type="monotone"
                    dataKey="total_active_paid_subs_count"
                    stackId="1"
                    stroke="#8884d8"
                    fill="#8884d8"
                  />
                  <Area type="monotone" dataKey="bluesnap_subs_count" stackId="2" stroke="#faa732" fill="#faa732" />
                  <Area type="monotone" dataKey="active_workspaces_count" stackId="3" stroke="#6c757d" fill="#6c757d" />
                </AreaChart>
              </ResponsiveContainer>
            </Col>
          </Row>
        </Spin>
      </Card>
    </PageLayout>
  );
}
