import { useRef } from 'react';
import { TableProps } from 'antd';
import { SorterResult } from 'antd/es/table/interface';

import { SortOrderApi, SortOrderMapper } from '@/libs';

import { usePowerleadWorkspaces } from '@/features/powerlead/workspace';

import type { WorkspaceFilters } from '../index';

type SortFields = {
  sort_by: string;
  sort_dir: SortOrderApi;
};

const toSortParams = (sorter: SorterResult<unknown> | SorterResult<unknown>[]): SortFields => {
  const defaultSort: SortFields = {
    sort_by: 'created_at',
    sort_dir: 'desc',
  };

  const [sort] = Array.isArray(sorter) ? sorter : [sorter];

  if (!sort.order || !sort.field) return defaultSort;

  return {
    sort_by: sort.field as string,
    sort_dir: SortOrderMapper.toApi(sort.order),
  };
};

export const useWorkspacesTableViewModel = ({
  filters: filters,
  onFiltersChange: onFiltersChange,
}: {
  filters: WorkspaceFilters;
  onFiltersChange(params: WorkspaceFilters): void;
}) => {
  const dataTotal = useRef(0);

  const { isLoading, data, isError } = usePowerleadWorkspaces(filters);
  if (data?.length) {
    dataTotal.current = data.length;
  }

  const onTableChange: TableProps['onChange'] = (pagination, _filters, sorter) => {
    onFiltersChange({
      ...filters,
      page: pagination.current || 1,
      start: (filters.length ?? 20) * ((pagination.current || 1) - 1),
      ...toSortParams(sorter),
    });
  };

  const onSearch = (value: string) => {
    onFiltersChange({ ...filters, page: 1, search: value });
  };

  return {
    dataSource: data?.workspaces || [],
    pagination: {
      current: filters.page as number,
      pageSize: filters.length,
      total: dataTotal.current,
      showSizeChanger: false,
    },
    isLoading,
    isError,
    onTableChange,
    onSearch,
  };
};
