import { ExclamationCircleFilled } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { App, Modal, Upload, UploadProps } from 'antd';

import { ApiUrl } from '@/libs';

import { authTokenManager } from '@/features/auth';
import {
  getPowerleadEnrichmentCsvStaticQueryKey,
  usePowerleadDeleteEnrichmentCsv,
  usePowerleadDownloadEnrichmentResult,
  usePowerleadEnrichmentCsvList,
} from '@/features/powerlead/enrichmentCsv';

const ROWS_PER_PAGE = 20;

export const useEnrichmentTableViewModel = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();
  const { data, isLoading, isError } = usePowerleadEnrichmentCsvList();
  const { mutate: deleteEnrichmentCsv } = usePowerleadDeleteEnrichmentCsv();
  const downloadEnrichmentResult = usePowerleadDownloadEnrichmentResult();

  const onClickDeleteItem = async (uuid: string) => {
    Modal.confirm({
      title: `Delete item: ${uuid}?`,
      icon: <ExclamationCircleFilled />,
      onOk: async () => {
        try {
          await deleteEnrichmentCsv(uuid);
          message.error('Enrichment csv deleted successfully');
        } catch (error) {
          message.error('Failed to delete enrichment csv');
          throw error;
        }
      },
    });
  };

  const onClickDownloadItem = async (uuid: string) => {
    try {
      await downloadEnrichmentResult(uuid);
      message.error('Enrichment csv downloaded successfully');
    } catch (error) {
      message.error('Failed to download enrichment csv');
      throw error;
    }
  };

  return {
    dataSource: data,
    pagination: {
      pageSize: ROWS_PER_PAGE,
    },
    isLoading,
    isError,
    uploadProps: {
      progress: {
        showInfo: false,
      },
      showUploadList: false,
      name: 'file',
      multiple: false,
      maxCount: 1,
      accept: 'text/csv',
      action: ApiUrl.baseUrl + ApiUrl.getPowerleadUrl('/csv-enrichment-admin'),
      headers: {
        authorization: `Bearer ${authTokenManager.getAuthTokens()?.access}`,
      },
      onChange(info) {
        if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
          queryClient.invalidateQueries({ queryKey: getPowerleadEnrichmentCsvStaticQueryKey() });
        } else if (info.file.status === 'error') {
          message.error(info.file.response.message || `${info.file.name} file upload failed.`);
        }
      },
      beforeUpload: (file) => {
        const isCSV = file.type === 'text/csv';
        if (!isCSV) {
          message.error(`${file.name} is not a .csv file format`);
        }
        return isCSV || Upload.LIST_IGNORE;
      },
    } as UploadProps,
    onClickDeleteItem,
    onClickDownloadItem,
  };
};
