import { useState } from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { Button } from 'antd';
import { fallback, number, object, optional, parse, string } from 'valibot';

import { PageLayout } from '@/components';

import { useBillingAccountId } from '@/features/billing';

import { AddCreditsModal } from './-components/AddCreditsModal';
import { CreditsTable } from './-components/CreditsTable';

const SearchSchema = object({
  limit: fallback(number(), 50),
  skip: fallback(optional(number(), 0), 0),
  account_email: optional(string(), ''),
});

export const Route = createFileRoute('/_authenticated/billing/credits/')({
  component: Credits,
  validateSearch: (search) => parse(SearchSchema, search),
});

function Credits() {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const search = Route.useSearch();
  const naviagate = Route.useNavigate();
  const accounID = useBillingAccountId(search.account_email);

  return (
    <PageLayout
      title="Credits"
      extra={
        <Button type="primary" onClick={() => setIsModalOpened(true)} disabled={!accounID.data}>
          Add Credits
        </Button>
      }
    >
      <AddCreditsModal
        isOpened={isModalOpened}
        onClose={() => setIsModalOpened(false)}
        accountId={accounID.data ?? ''}
      />
      <CreditsTable
        filters={{
          account_id: accounID.data ?? '',
          limit: search.limit,
          skip: search.skip,
        }}
        onFiltersChange={(newSearch) =>
          naviagate({
            search: (prevSearch) => ({
              ...prevSearch,
              limit: newSearch.limit,
              skip: newSearch.skip,
            }),
          })
        }
      />
    </PageLayout>
  );
}
