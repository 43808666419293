import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { App } from 'antd';

import { PageLayout } from '@/components';

import { ProductForm, useCreateProduct } from '@/features/billing';

export const Route = createFileRoute('/_authenticated/billing/products/create/')({
  component: CreateProduct,
});

export function CreateProduct() {
  const createProduct = useCreateProduct();
  const { message } = App.useApp();
  const navigate = useNavigate();

  return (
    <PageLayout title="Create Product">
      <ProductForm
        onSubmit={async (values) => {
          try {
            await createProduct.mutateAsync(values);
            message.success('Product created successfully.');
            navigate({ to: '/billing/products' });
          } catch (error) {
            message.error('An error occurred. Please try again.');
            throw error;
          }
        }}
      />
    </PageLayout>
  );
}
