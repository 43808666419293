import { useMemo, useState } from 'react';
import dayjs from 'dayjs';

import { PowerleadTotalStats, usePowerleadTotalStats } from '@/features/powerlead/report';

enum Period {
  Day = 'day',
  FewDays = 'fewDays',
  Week = 'week',
  Month = 'month',
}

type PeriodStats = {
  newAccountsTotal: number;
  statItems: PowerleadTotalStats[];
};

const getTimestampRangesByPeriod = (
  period: Period,
): {
  current: [number, number];
  previous: [number, number];
} => {
  const now = dayjs().toDate();

  switch (period) {
    case Period.FewDays:
      return {
        current: [dayjs(now).subtract(3, 'days').unix(), dayjs(now).unix()],
        previous: [dayjs(now).subtract(6, 'days').unix(), dayjs(now).subtract(3, 'days').unix()],
      };
    case Period.Week:
      return {
        current: [dayjs(now).subtract(1, 'weeks').unix(), dayjs(now).unix()],
        previous: [dayjs(now).subtract(2, 'weeks').unix(), dayjs(now).subtract(1, 'weeks').unix()],
      };
    case Period.Month:
      return {
        current: [dayjs(now).subtract(1, 'months').unix(), dayjs(now).unix()],
        previous: [dayjs(now).subtract(2, 'months').unix(), dayjs(now).subtract(1, 'months').unix()],
      };
    default:
      return {
        current: [dayjs(now).subtract(1, 'days').unix(), dayjs(now).unix()],
        previous: [dayjs(now).subtract(2, 'days').unix(), dayjs(now).subtract(1, 'days').unix()],
      };
      break;
  }
};

const calculatePeriodStats = (stats: PowerleadTotalStats[]): PeriodStats => {
  const result: PeriodStats = {
    newAccountsTotal: 0,
    statItems: [],
  };
  stats.forEach((item) => {
    result.newAccountsTotal += item.new_accounts_count;
    result.statItems.push(item);
  });

  return result;
};

const selectItems = [
  {
    label: '1 Day',
    value: Period.Day,
  },
  {
    label: '3 Days',
    value: Period.FewDays,
  },
  {
    label: '1 Week',
    value: Period.Week,
  },
  {
    label: '1 Month',
    value: Period.Month,
  },
];

export const useTotalStatsViewModel = () => {
  const [period, setPeriod] = useState<Period>(Period.Day);
  const { current: currentRange, previous: previousRnage } = useMemo(() => {
    return getTimestampRangesByPeriod(period);
  }, [period]);

  const {
    data: current,
    isLoading: currentIsLoading,
    isError: currentIsError,
  } = usePowerleadTotalStats({ from_dt: currentRange[0], to_dt: currentRange[1] });
  const {
    data: previous,
    isLoading: previousIsLoading,
    isError: previousIsError,
  } = usePowerleadTotalStats({ from_dt: previousRnage[0], to_dt: previousRnage[1] });

  return {
    selectItems,
    selectedItem: selectItems.find((item) => item.value === period) as (typeof selectItems)[0],
    current: calculatePeriodStats(current ?? []),
    previous: calculatePeriodStats(previous ?? []),
    isLoading: currentIsLoading || previousIsLoading,
    isError: currentIsError || previousIsError,
    onChnagePeriod: setPeriod,
  };
};
