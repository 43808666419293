import { FC } from 'react';
import { Card, Form, Select, Spin } from 'antd';
import { Bar, BarChart, Cell, Tooltip, XAxis, YAxis } from 'recharts';

import { useEngagementStatsViewModel } from './hooks/useEngagementStatsViewModel';

import styles from './styles.module.scss';

export const EngagementStats: FC = () => {
  const { data, selectItems, onChnagePeriod, selectedItem, isError, isLoading } = useEngagementStatsViewModel();

  return (
    <Card
      title="Engagement stats"
      extra={
        <Form.Item label="Select period" className={styles.formItem}>
          <Select
            className={styles.select}
            defaultValue={selectedItem.value}
            onChange={onChnagePeriod}
            options={selectItems}
          />
        </Form.Item>
      }
    >
      <Spin spinning={isError || isLoading}>
        <BarChart width={800} height={400} data={data}>
          <YAxis dataKey="value" />
          <XAxis dataKey="label" />
          <Tooltip />
          <Bar dataKey="value" fill="#82ca9d">
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Bar>
        </BarChart>
      </Spin>
    </Card>
  );
};
