import { useQuery } from '@tanstack/react-query';

import { httpClient } from '@/libs';

import { useAppType } from '@/features/app';

import { getSubscriptionStatisticQueryKey } from '../keys';
import type { SubscriptionTotalStatistic } from '../types';

export const useSubscriptionStatistic = (params: { period: 'year' | 'month' | 'week' }) => {
  const app = useAppType();

  return useQuery({
    queryFn: async () => {
      const response = await httpClient.get<SubscriptionTotalStatistic>('/dashboard_data', {
        params: { app, ...params },
      });
      return response.data;
    },
    queryKey: getSubscriptionStatisticQueryKey(app, params),
  });
};
