import { createFileRoute } from '@tanstack/react-router';
import { Space } from 'antd';

import { PageLayout } from '@/components';

import { CollaborationStats } from './-components/CollaborationStats';
import { EngagementStats } from './-components/EngagementStats';
import { TotalStats } from './-components/TotalStats';

export const Route = createFileRoute('/_authenticated/reports/')({
  component: Reports,
});

function Reports() {
  return (
    <PageLayout title="Reports">
      <Space direction="vertical">
        <CollaborationStats />
        <TotalStats />
        <EngagementStats />
      </Space>
    </PageLayout>
  );
}
