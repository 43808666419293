import { FC } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';

import styles from './styles.module.scss';

export interface EmailCellProps {
  value: string;
  isVerified: boolean;
}

export const EmailCell: FC<EmailCellProps> = ({ value, isVerified }) => {
  return (
    <Space direction="horizontal">
      <Typography>{value}</Typography>
      <div title={isVerified ? 'Email is verified' : 'Email is not verified'} className={styles.iconWrapper}>
        {isVerified ? <CheckOutlined className={styles.green} /> : <CloseOutlined className={styles.red} />}
      </div>
    </Space>
  );
};
