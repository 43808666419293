import { FC } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { Button, Result } from 'antd';

export const NotFoundLayout: FC = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button
          type="primary"
          onClick={() => {
            navigate({ to: '/' });
          }}
        >
          Back Home
        </Button>
      }
    />
  );
};
