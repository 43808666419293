import { useState } from 'react';
import type { MenuProps } from 'antd';

import { Purchase, useDeletePurchase } from '@/features/billing';

export const usePurchaseActionsViewModel = (item: Purchase) => {
  const [visibleJsonViwer, setVisibleJsonViwer] = useState(false);
  const [visibleDeleteGift, setVisibleDeleteGift] = useState(false);
  const deletePurchase = useDeletePurchase();

  const items: MenuProps['items'] = [
    {
      label: 'Details',
      key: '0',
      onClick: () => {
        setVisibleJsonViwer(true);
      },
    },
  ];

  if (item.purchase_type === 'gift') {
    items.push({
      label: 'Delete Gift',
      key: '1',
      onClick: () => {
        setVisibleDeleteGift(true);
      },
      danger: true,
    });
  }

  const onDeleteGift = async () => {
    await deletePurchase.mutateAsync(item.id);
    setVisibleDeleteGift(false);
  };

  return {
    items,
    visibleJsonViwer,
    visibleDeleteGift,
    isDeleteLoading: deletePurchase.isPending,
    onDeleteGift,
    setVisibleDeleteGift,
    setVisibleJsonViwer,
  };
};
