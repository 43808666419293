import { FC, useState } from 'react';
import { Button, Card, Form, Input, InputNumber, Select, Spin, Switch } from 'antd';

import { useProductTypes } from '../../hooks/useProductTypes';
import type { Product } from '../../types';

type ProductFormProps = {
  initialValues?: Product;
  onSubmit: (product: Product) => Promise<void>;
};

const FormItem = Form.Item<Product>;

export const ProductForm: FC<ProductFormProps> = ({ onSubmit, initialValues }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { data: productTypes, isLoading } = useProductTypes();

  const handleSubmit = async (product: Product) => {
    setIsProcessing(true);
    try {
      await onSubmit({ ...product });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Spin spinning={isLoading}>
      <Card>
        <Form<Product>
          name="product-form"
          initialValues={initialValues}
          disabled={isProcessing}
          labelCol={{ span: 6 }}
          onFinish={handleSubmit}
        >
          <FormItem name="id" label="ID" hidden={!initialValues}>
            <Input disabled />
          </FormItem>
          <FormItem name="app" label="App" hidden={!initialValues}>
            <Input disabled />
          </FormItem>
          <FormItem name="label" label="Label" rules={[{ required: true }]}>
            <Input />
          </FormItem>
          <FormItem name="product_type_id" label="Product Type" rules={[{ required: true }]}>
            <Select>
              {productTypes?.map((productType) => (
                <Select.Option key={productType.id} value={productType.id}>
                  {productType.label}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
          <FormItem name="google_product_id" label="Google Product ID">
            <Input />
          </FormItem>
          <FormItem name="apple_product_id" label="Apple Product ID">
            <Input />
          </FormItem>
          <FormItem name="bluesnap_product_id" label="Bluesnap Product ID">
            <Input />
          </FormItem>
          <FormItem name="stripe_product_id" label="Stripe Product ID">
            <Input />
          </FormItem>
          <FormItem name="stripe_initial_product_id" label="Stripe Initial Product ID">
            <Input />
          </FormItem>
          <FormItem name="description" label="Description">
            <Input />
          </FormItem>
          <FormItem name="price" label="Price">
            <InputNumber min={0} />
          </FormItem>
          <FormItem name="duration_days" label="Duration (days)">
            <InputNumber min={0} />
          </FormItem>
          <FormItem name="auto_renewable" label="Auto Renewable">
            <Switch />
          </FormItem>
          <FormItem name="activatable" label="Activatable">
            <Switch />
          </FormItem>
          <FormItem name="one_time_product" label="One Time Product">
            <Switch />
          </FormItem>
          <FormItem name="debug" label="Debug">
            <Switch />
          </FormItem>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
};
