import { useRef } from 'react';
import type { TableProps } from 'antd';

import { getPage, getSkip } from '@/libs/pagination';

import { useProducts, usePurchases, usePurchaseTypes } from '@/features/billing';

import type { PurchasesTableFilters, PurchasesTableProps } from '../types';

const DEFAULT_LIMIT = 50;

export const usePurchaseTableViewModel = ({ filters, onFiltersChange }: PurchasesTableProps) => {
  const totalRef = useRef(filters.limit);
  const products = useProducts();
  const purchaseTypes = usePurchaseTypes();

  const purchases = usePurchases(filters);

  if (purchases.data && 'total' in purchases.data) {
    totalRef.current = purchases.data.total ?? 0;
  }

  const pagination: TableProps['pagination'] = {
    total: totalRef.current,
    current: getPage({
      skip: filters.skip,
      limit: filters.limit ?? DEFAULT_LIMIT,
    }),
    pageSize: filters.limit,
    position: ['topLeft', 'bottomLeft'],
    showSizeChanger: true,
  };

  const handleFiltersChange = (newFilters: PurchasesTableFilters) => {
    onFiltersChange({
      ...filters,
      skip: 0,
      ...newFilters,
    });
  };

  const handlePageChange = (page?: number, pageSize?: number) => {
    const limit = pageSize ?? filters.limit ?? DEFAULT_LIMIT;

    onFiltersChange({
      ...filters,
      limit,
      skip: getSkip({
        page,
        limit,
      }),
    });
  };

  const handleReset = () => {
    onFiltersChange({
      limit: filters.limit,
    });
  };

  return {
    purchases,
    products,
    purchaseTypes,
    pagination,
    handleFiltersChange,
    handlePageChange,
    handleReset,
  };
};
