import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useAppType } from '@/features/app';

import { getProductQueryKey, getProductsQueryKey } from '../keys';
import { BillingHttpClient } from '../libs';
import { Product } from '../types';

export const useProduct = (productId: Product['id']) => {
  const app = useAppType();
  const queryClient = useQueryClient();

  return useQuery({
    enabled: !!productId,
    queryKey: getProductQueryKey(app, productId),
    queryFn: async () => {
      return BillingHttpClient.get<Product>(app, `/api/v1/products/${productId}`);
    },
    initialData: () => {
      const products = queryClient.getQueryData<Product[]>(getProductsQueryKey(app));
      return products?.find((product) => product.id === productId);
    },
  });
};
