import { AxiosRequestConfig } from 'axios';

import { httpClient } from '@/libs';

import type { AppType } from '@/features/app';

type BillingRequestBody = {
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
  url: string;
  app_id: AppType;
  body?: unknown;
};

export class BillingHttpClient {
  private static url = '/billing_request';

  private static async performRequest<T>(body: BillingRequestBody, config?: AxiosRequestConfig): Promise<T> {
    const response = await httpClient.post<T>(BillingHttpClient.url, body, config);

    const data = response.data;

    if (data && typeof data === 'object' && 'detail' in data && Array.isArray(data.detail)) {
      throw new Error('Billing Api Error');
    }

    return response.data;
  }

  static async get<T>(app: AppType, url: string, config?: AxiosRequestConfig): Promise<T> {
    return BillingHttpClient.performRequest<T>(
      {
        method: 'GET',
        url,
        body: null,
        app_id: app,
      },
      config,
    );
  }

  static async post<T>(app: AppType, url: string, body: unknown, config?: AxiosRequestConfig): Promise<T> {
    return BillingHttpClient.performRequest<T>(
      {
        method: 'POST',
        url,
        app_id: app,
        body,
      },
      config,
    );
  }

  static async patch<T>(app: AppType, url: string, body: unknown): Promise<T> {
    return BillingHttpClient.performRequest<T>({
      method: 'PATCH',
      url,
      app_id: app,
      body,
    });
  }

  static async put<T>(app: AppType, url: string, body: unknown): Promise<T> {
    return BillingHttpClient.performRequest<T>({
      method: 'PUT',
      url,
      app_id: app,
      body,
    });
  }

  static async delete<T>(app: AppType, url: string, config?: AxiosRequestConfig): Promise<T> {
    return BillingHttpClient.performRequest<T>(
      {
        method: 'DELETE',
        url,
        app_id: app,
      },
      config,
    );
  }
}
