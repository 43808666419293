import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiUrl, httpClient } from '@/libs';

import { getPowerleadWorkspacesQueryKey } from '@/features/powerlead/workspace';

const API_URL = ApiUrl.getPowerleadUrl('/debug/delete_account');

export const useDeletePowerleadWorkspace = (params: Record<string, unknown>) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (email: string) => {
      const response = await httpClient.post<boolean>(API_URL, { email: email });

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getPowerleadWorkspacesQueryKey(params) });
    },
  });
};
