import { useEffect, useRef, useState } from 'react';

export const useStatsLineChartViewModel = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      if (!ref.current) return;

      const { width, height } = ref.current.getBoundingClientRect();
      setSize({ width, height });
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    size,
    ref,
  };
};
