import { useMemo, useState } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { MenuProps } from 'antd';

import { PowerleadWorkspace, useDeletePowerleadWorkspace } from '@/features/powerlead/workspace';

import type { WorkspaceFilters } from '../../index';

export const useTableActionsCellViewModel = ({
  item,
  searchParams,
}: {
  item: PowerleadWorkspace;
  searchParams: WorkspaceFilters;
}) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const deleteWorkspace = useDeletePowerleadWorkspace(searchParams);

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const items = useMemo(() => {
    const items: MenuProps['items'] = [
      {
        label: 'Purchases',
        key: 1,
        onClick() {
          navigate({ to: '/billing/purchases', search: { accountEmail: item.owner_email, skip: 0, limit: 50 } });
        },
      },
      {
        label: 'Workspace credits',
        key: 2,
        onClick() {
          navigate({ to: '/billing/credits', search: { account_email: item.owner_email, skip: 0, limit: 50 } });
        },
      },
      {
        label: 'Delete workspace',
        key: 3,
        danger: true,
        onClick: toggleModal,
      },
    ];

    if (item.subscription.stripe_customer_id) {
      items.unshift({
        label: 'Stripe settings',
        key: 0,
        onClick() {
          window.open(`https://dashboard.stripe.com/customers/${item.subscription.stripe_customer_id}`, '_blank');
        },
      });
    }

    return items;
  }, [item.subscription.stripe_customer_id]);

  const onConfirmDeleteWorkspace = async () => {
    await deleteWorkspace.mutateAsync(item.owner_email);
  };

  return {
    items,
    isModalOpen,
    toggleModal,
    onConfirmDeleteWorkspace,
  };
};
