import { message } from 'antd';

import { FileHelper } from '@/libs';

import { PowerleadCollaborationStats, usePowerleadCollaboratioStats } from '@/features/powerlead/report';

const metadataMapByField: Record<keyof PowerleadCollaborationStats, { label: string }> = {
  total_workspaces_count: {
    label: 'Total workspaces',
  },
  collaboration_workspaces_count: {
    label: 'Collaboration workspaces',
  },
  active_workspaces_count: {
    label: 'Active workspaces',
  },
  active_2plus_workspaces_count: {
    label: 'Active 2 plus workspaces',
  },
  total_members_count: {
    label: 'Total members',
  },
  approved_members_count: {
    label: 'Approved members',
  },
  collaboration_accounts_count: {
    label: 'Collaboration accounts',
  },
  trial_plan_count: {
    label: 'Trial plans',
  },
  free_plan_count: {
    label: 'Free plans',
  },
  monthly_plan_count: {
    label: 'Monthly plans',
  },
  annually_plan_count: {
    label: 'Annual plans',
  },
  canceled_paid_subs_count: {
    label: 'Canceled paid subscriptions',
  },
  grace_period_subs_count: {
    label: 'Grace period subscriptions',
  },
  expired_paid_subs_count: {
    label: 'Expired paid subscriptions',
  },
  total_active_paid_subs_count: {
    label: 'Active paid subscriptions',
  },
  bluesnap_subs_count: {
    label: 'Bluesnap subscriptions',
  },
  google_subs_count: {
    label: 'Google subscriptions',
  },
  approved_members_count_distribution: {
    label: 'Approved members distribution',
  },
};

export const useCollaborationStatsViewModel = () => {
  const { data, isLoading, isError } = usePowerleadCollaboratioStats();
  const [messageApi, contextHolder] = message.useMessage();

  const onExport = () => {
    if (!data) return;

    FileHelper.exportToJson(data, 'collaboration_stats');
    messageApi.success('Collaboration stats exported successfully');
  };

  return {
    onExport,
    data: data
      ? Object.entries(data).map(([key, value]) => {
          const label = metadataMapByField[key as keyof PowerleadCollaborationStats].label;
          if (value instanceof Object) {
            return {
              label,
              value: Object.entries(value)
                .reduce((acc, [, value]) => {
                  acc += value;
                  return acc;
                }, 0)
                .toString(),
            };
          }
          return {
            label,
            value,
          };
        })
      : [],
    isLoading,
    isError,
    contextHolder,
  };
};
