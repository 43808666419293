import { UploadOutlined } from '@ant-design/icons';
import { Button, Card, Flex, Table, Upload } from 'antd';

import { DateCell } from '@/components';

import { PowerleadEnrichmentCsvItem } from '@/features/powerlead/enrichmentCsv';

import { useEnrichmentTableViewModel } from './hooks/useEnrichmentTableViewModel';

const QuotaColumn = Table.Column<PowerleadEnrichmentCsvItem>;

export const EnrichmentTable = () => {
  const { dataSource, pagination, isLoading, onClickDeleteItem, onClickDownloadItem, uploadProps } =
    useEnrichmentTableViewModel();

  return (
    <Card
      title="Upload CSV to enrich"
      extra={
        <Upload {...uploadProps}>
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
      }
    >
      <Table
        dataSource={dataSource}
        pagination={{ ...pagination, position: ['topLeft', 'bottomLeft'] }}
        loading={isLoading}
        key="table"
        bordered
        scroll={{ x: 'max-content' }}
      >
        <QuotaColumn title="ID" dataIndex="uuid" key="uuid" />
        <QuotaColumn title="File Name" dataIndex="filename" key="filename" />
        <QuotaColumn
          title="Created date"
          dataIndex="created_at"
          key="created_at"
          render={(createdAt) => <DateCell date={createdAt} />}
        />
        <QuotaColumn title="Created by" dataIndex="created_by" key="created_by" />
        <QuotaColumn title="Status" dataIndex="status" key="status" />
        <QuotaColumn
          title=""
          key="actions"
          render={(item) => {
            return (
              <Flex justify="flex-end" gap={10}>
                <Button
                  type="primary"
                  onClick={() => {
                    onClickDownloadItem(item.uuid);
                  }}
                >
                  Download
                </Button>
                <Button
                  onClick={() => {
                    onClickDeleteItem(item.uuid);
                  }}
                >
                  Delete
                </Button>
              </Flex>
            );
          }}
        />
      </Table>
    </Card>
  );
};
