import { createFileRoute } from '@tanstack/react-router';
import { fallback, number, object, optional, parse, string } from 'valibot';

import { PageLayout } from '@/components';

import { BlacklistTable } from './-components/BlacklistTable';
import { useBlackListViewModel } from './-hooks/useBlackListViewModel';

const SearchSchema = object({
  page: fallback(optional(number(), 1), 1),
  search: optional(string()),
});

export const Route = createFileRoute('/_authenticated/blacklist/')({
  component: Blacklist,
  validateSearch: (
    search,
  ): {
    page: number;
  } => parse(SearchSchema, search),
});

function Blacklist() {
  const { searchParams, navigate } = useBlackListViewModel();

  return (
    <PageLayout title="Blacklist">
      <BlacklistTable
        filters={searchParams}
        onFilterChange={(filters) => {
          navigate({ search: filters });
        }}
      />
    </PageLayout>
  );
}
