import { FC } from 'react';
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';

import { PowerleadTotalStats } from '@/features/powerlead/report';

import { useStatsLineChartViewModel } from './hooks/useStatsLineChartViewModel';

import styles from './styles.module.scss';

export const StatsLineChart: FC<{ data: PowerleadTotalStats[] }> = ({ data = [] }) => {
  const { size, ref } = useStatsLineChartViewModel();

  return (
    <div ref={ref} className={styles.wrapper}>
      <LineChart width={size.width} height={size.height} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="at" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="new_accounts_count" name="New accounts" stroke="#8884d8" activeDot={{ r: 8 }} />
        <Line
          type="monotone"
          dataKey="total_free_accounts_count"
          name="Total free accounts"
          stroke="#82ca9d"
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          dataKey="total_paid_accounts_count"
          name="Total paid accounts"
          stroke="#f50f2e"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </div>
  );
};
