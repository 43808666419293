import { useState } from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { App, MenuProps, Modal, Typography } from 'antd';

import { ApiUrl, httpClient } from '@/libs';

import { getPowerleadAccountsStaticQueryKey, PowerleadAccount } from '@/features/powerlead/account';

const DELETE_ACCOUNT_API_URL = ApiUrl.getPowerleadUrl('/debug/delete_account');
const GET_ACCESS_TOKEN_API_URL = ApiUrl.getPowerleadUrl('/admin/auth_token');

const deleteAccount = async (email: string): Promise<{ email: string }> => {
  const response = await httpClient.post<{ email: string }>(DELETE_ACCOUNT_API_URL, { email });
  return response.data;
};

const getAccountAccessToken = async (
  email: string,
): Promise<{
  token: string;
}> => {
  const response = await httpClient.post<{
    token: string;
  }>(GET_ACCESS_TOKEN_API_URL, { email });
  return response.data;
};

export const useTableActionsCellViewModel = (item: PowerleadAccount) => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();
  const [visibleJsonViwer, setVisibleJsonViwer] = useState(false);

  const items: MenuProps['items'] = [
    {
      label: 'Account details',
      key: '0',
      onClick: () => {
        setVisibleJsonViwer(true);
      },
    },
    {
      label: 'Get Access Token',
      key: '1',
      onClick: async () => {
        try {
          const { token } = await getAccountAccessToken(item.email);
          Modal.info({
            title: `Access token for email - ${item.email}`,
            content: <Typography.Text copyable>{token}</Typography.Text>,
            onOk() {},
          });
        } catch (e) {
          message.error('Failed to get access token');
        }
      },
    },
    {
      label: 'Delete',
      key: '3',
      onClick: () => {
        Modal.confirm({
          title: `Delete account with email - ${item.email}?`,
          icon: <ExclamationCircleFilled />,
          onOk: async () => {
            try {
              await deleteAccount(item.email);
              await queryClient.invalidateQueries({
                queryKey: getPowerleadAccountsStaticQueryKey(),
                exact: false,
                refetchType: 'active',
              });
            } catch (error) {
              message.error('Failed to delete account');
            }
          },
        });
      },
    },
  ];

  return {
    items,
    visibleJsonViwer,
    setVisibleJsonViwer,
  };
};
