import { FC } from 'react';
import { Link } from '@tanstack/react-router';
import { Button, Card, Flex, Form, Input, Select, Switch, Table } from 'antd';

import { BooleanCell, DateCell } from '@/components';

import type { Purchase, PurchaseType } from '@/features/billing';

import { PurchaseActions } from './components/PurchaseActions';
import { usePurchaseTableViewModel } from './hooks/usePurchaseTableViewModel';
import type { PurchasesTableProps } from './types';

import styles from './styles.module.scss';

const FormItem = Form.Item<PurchaseType>;
const TableColumn = Table.Column<Purchase>;

export const PurchasesTable: FC<PurchasesTableProps> = (props) => {
  const { handleFiltersChange, handleReset, handlePageChange, pagination, products, purchaseTypes, purchases } =
    usePurchaseTableViewModel(props);

  return (
    <Flex vertical gap="20px">
      <Card title="Filters">
        <Flex justify="space-between" align="flex-end">
          <Form name="purchase-filter" layout="inline" initialValues={props.filters} onFinish={handleFiltersChange}>
            <FormItem name="productId" label="Product" layout="vertical">
              <Select placeholder="Product" disabled={products.isLoading} className={styles.select} allowClear>
                {products.data?.map((product) => (
                  <Select.Option key={product.id} value={product.id}>
                    {product.label}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
            <FormItem name="purchaseType" label="Purchase type" layout="vertical">
              <Select placeholder="Purchase type" className={styles.select} allowClear>
                {purchaseTypes?.map((purchaseType) => (
                  <Select.Option key={purchaseType} value={purchaseType}>
                    {purchaseType}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
            <FormItem name="accountEmail" label="Account email" layout="vertical">
              <Input placeholder="Account email" />
            </FormItem>
            <FormItem name="deleted" label="Only deleted" layout="vertical">
              <Switch />
            </FormItem>
            <FormItem name="canceled" label="Only canceled" layout="vertical">
              <Switch />
            </FormItem>
          </Form>
          <Flex gap="10px">
            <Button type="default" htmlType="reset" form="purchase-filter" onClick={handleReset}>
              Clear
            </Button>
            <Button type="primary" htmlType="submit" form="purchase-filter">
              Filter
            </Button>
          </Flex>
        </Flex>
      </Card>
      <Card>
        <Table
          bordered
          dataSource={purchases.data?.items ?? []}
          loading={purchases.isLoading}
          pagination={pagination}
          onChange={(pagination) => handlePageChange(pagination.current, pagination.pageSize)}
          scroll={{ x: 'max-content' }}
        >
          <TableColumn
            dataIndex="account_email"
            title="Account email"
            width={100}
            render={(email) => (
              <Link to={'/workspaces'} search={{ search: email }}>
                {email}
              </Link>
            )}
          />
          <TableColumn
            dataIndex="product_label"
            title="Product"
            width={100}
            render={(_, item) => (
              <Link to="/billing/products/$productId" params={{ productId: item.product_id }}>
                {item.product_label}
              </Link>
            )}
          />
          <TableColumn dataIndex="purchase_type" title="Purchase type" width={50} />
          <TableColumn dataIndex="purchase_date" title="Purchase date" render={(date) => <DateCell date={date} />} />
          <TableColumn
            dataIndex="expiration_date"
            title="Expiration date"
            render={(date) => <DateCell date={date} />}
          />
          <TableColumn dataIndex="deleted" title="Deleted" render={(deleted) => <BooleanCell value={deleted} />} />
          <TableColumn dataIndex="canceled" title="Canceled" render={(canceled) => <BooleanCell value={canceled} />} />
          <TableColumn dataIndex="id" title="Actions" render={(_, item) => <PurchaseActions item={item} />} />
        </Table>
      </Card>
    </Flex>
  );
};
