import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAppType } from '@/features/app';

import { getProductsQueryKey } from '../keys';
import { BillingHttpClient } from '../libs';
import type { Product } from '../types';

export const useCreateProduct = () => {
  const app = useAppType();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (product: Product) => {
      return BillingHttpClient.post<Product>(app, '/api/v1/products/', { ...product, app });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getProductsQueryKey(app),
      });
    },
  });
};
