import { useQuery } from '@tanstack/react-query';

import { ApiUrl, httpClient } from '@/libs';

import { getPowerleadTotalStatsQueryKey } from '../keys';
import { PowerleadTotalStats } from '../types';

const getTotalStats = async (params: { from_dt: number; to_dt: number }): Promise<PowerleadTotalStats[]> => {
  const resonse = await httpClient.get<PowerleadTotalStats[]>(ApiUrl.getPowerleadUrl('/admin/stats'), { params });
  return resonse.data;
};

export const usePowerleadTotalStats = (params: { from_dt: number; to_dt: number }) => {
  return useQuery<PowerleadTotalStats[]>({
    queryKey: getPowerleadTotalStatsQueryKey(params),
    queryFn: () => getTotalStats(params),
    retryDelay: 30_000,
  });
};
