import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiUrl, httpClient } from '@/libs';

import { getPowerleadDomainBlacklistStaticQueryKey } from '../keys';

export const usePowerleadDomainBlacklistDelete = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (domain: string): Promise<boolean> => {
      const response = await httpClient.delete<boolean>(
        ApiUrl.getPowerleadUrl(`/admin/delete-blacklisted-domain/${domain}`),
      );
      return response.data;
    },
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: getPowerleadDomainBlacklistStaticQueryKey(),
      });
    },
  });
};
