import { useQuery } from '@tanstack/react-query';

import { ApiUrl, httpClient } from '@/libs';

import { getPowerleadWorkspacesQueryKey } from '../keys.ts';
import { PowerleadWorkspace } from '../types.ts';

const API_URL = ApiUrl.getPowerleadUrl('/admin/workspaces');

type PowerleadWorkspacesResult = {
  workspaces: PowerleadWorkspace[];
  length: number;
};

export const usePowerleadWorkspaces = (params: Record<string, unknown>) => {
  return useQuery<PowerleadWorkspacesResult, Error>({
    queryKey: getPowerleadWorkspacesQueryKey(params),
    queryFn: async () => {
      const response = await httpClient.get<PowerleadWorkspacesResult>(API_URL, {
        params: params,
      });
      return response.data;
    },
  });
};
