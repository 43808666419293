import { useState } from 'react';
import { App } from 'antd';

import { usePowerleadDomainBlacklistAdd } from '@/features/powerlead/blacklist';

export const useAddItemButtonViewModel = () => {
  const { message } = App.useApp();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { mutateAsync, isError, isPending } = usePowerleadDomainBlacklistAdd();

  const onClickButton = () => {
    setIsModalVisible(true);
  };

  const onSubmitForm = async (params: { domainOrRegx: { value: string }[]; isRegex?: boolean; notes?: string }) => {
    try {
      await mutateAsync({
        domains: params.domainOrRegx.map((item) => item.value),
        is_regex: params.isRegex,
        notes: params.notes,
      });
      setIsModalVisible(false);
      message.error('Domain added to blacklist.');
    } catch (error) {
      message.error('Failed to add domain to blacklist. Please try again later.');
      throw error;
    }
  };

  return {
    isModalVisible,
    onCloseModal: () => setIsModalVisible(false),
    onClickButton,
    isError,
    isPending,
    onSubmitForm,
  };
};
