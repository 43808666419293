import Cookie from 'js-cookie';

import { AuthTokens } from '../types';

const key = 'pw-admin.auth';

export class AuthTokenManager {
  public setAuthTokens(tokens: AuthTokens): void {
    Cookie.set(key, JSON.stringify(tokens));
  }

  public getAuthTokens(): AuthTokens | null {
    const storedData = Cookie.get(key);
    if (!storedData) return null;

    return JSON.parse(storedData as string);
  }

  public removeAuthTokens(): void {
    Cookie.remove(key);
  }
}

export const authTokenManager = new AuthTokenManager();
