import { FC } from 'react';
import { Card, Result, Table } from 'antd';

import { BooleanCell } from '@/components';

import type { ProductType, ProductTypeQuota } from '@/features/billing';

import { useProductTypesTableViewModel } from './hooks/useProductTypesTableViewModel';
import { ProductTypeActions } from './components';

import styles from './styles.module.scss';

const ProductTypeColumn = Table.Column<ProductType>;
const QuotaColumn = Table.Column<ProductTypeQuota>;

export const ProductTypesTable: FC = () => {
  const { isLoading, isError, dataSource } = useProductTypesTableViewModel();

  if (isError) return <Result status="500" title="500" subTitle="Sorry, something happened" />;

  return (
    <Card>
      <Table
        rowKey={'id'}
        loading={isLoading}
        dataSource={dataSource}
        bordered
        scroll={{ x: 'max-content' }}
        pagination={false}
        expandable={{
          expandedRowClassName: () => styles.nestedTable,
          expandedRowRender: (record: ProductType) => {
            return (
              <Card title="Quotas" size="small">
                <Table dataSource={record.quotas} bordered pagination={false} size="small">
                  <QuotaColumn dataIndex="credit_type" title="Credit type" />
                  <QuotaColumn dataIndex="credits" title="# Credits" />
                  <QuotaColumn dataIndex="duration_days" title="Duration (days)" />
                  <QuotaColumn
                    dataIndex="renewable"
                    title="Renewable"
                    render={(renewable) => <BooleanCell value={renewable} />}
                  />
                </Table>
              </Card>
            );
          },
        }}
      >
        <ProductTypeColumn dataIndex="label" title="Label" />
        <ProductTypeColumn dataIndex="app" title="App" />
        <ProductTypeColumn
          dataIndex="id"
          title="Actions"
          render={(_, productType) => <ProductTypeActions productType={productType} />}
        />
      </Table>
    </Card>
  );
};
