import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { Button } from 'antd';

import { PageLayout } from '@/components';

import { ProductsTable } from './-components/ProductsTable';

export const Route = createFileRoute('/_authenticated/billing/products/')({
  component: Products,
});

function Products() {
  const navigate = useNavigate();

  return (
    <PageLayout
      title="Products"
      extra={
        <Button type="primary" onClick={() => navigate({ to: '/billing/products/create' })}>
          Create Product
        </Button>
      }
    >
      <ProductsTable />
    </PageLayout>
  );
}
