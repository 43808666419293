import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAppType } from '@/features/app';

import { getProductTypesQueryKey } from '../keys';
import { BillingHttpClient } from '../libs';

export const useDeleteProductType = () => {
  const app = useAppType();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) => {
      await BillingHttpClient.delete(app, `/api/v1/producttypes/${id}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getProductTypesQueryKey(app), refetchType: 'active' });
    },
  });
};
