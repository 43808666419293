import { FC } from 'react';

import { ActionsCell } from '@/components';

import type { Credit } from '@/features/billing';

import { useCreditActionsViewModel } from './useCreditActionsViewModel';

export const CreditActions: FC<{ item: Credit }> = ({ item }) => {
  const { items } = useCreditActionsViewModel(item);
  return <ActionsCell items={items} />;
};
