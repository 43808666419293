import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAppType } from '@/features/app';

import { getProductTypesQueryKey } from '../keys';
import { BillingHttpClient } from '../libs';
import type { ProductType } from '../types';

export const useCreateProductType = () => {
  const queryClient = useQueryClient();
  const appType = useAppType();

  return useMutation({
    mutationFn: async (productType: Omit<ProductType, 'id'>) => {
      return BillingHttpClient.post<ProductType>(appType, '/api/v1/producttypes/', productType);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getProductTypesQueryKey(appType) });
    },
  });
};
