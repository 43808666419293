import { FC } from 'react';
import { Link } from '@tanstack/react-router';

import { useProduct } from '@/features/billing';

type ProductCellProps = {
  id: string;
};

export const ProductCell: FC<ProductCellProps> = ({ id }) => {
  const product = useProduct(id);

  if (!product.data) return null;

  return (
    <Link
      to="/billing/products/$productId"
      params={{
        productId: id,
      }}
    >
      {product.data.label}
    </Link>
  );
};
