import { FC, useState } from 'react';
import { Form, Input, InputNumber, Modal, Select } from 'antd';

import { Credit, useAddCredits, useCreditTypes } from '@/features/billing';

type AddCreditsModalProps = {
  accountId: string;
  isOpened: boolean;
  onClose: () => void;
};

type AddCreditsFormValues = Pick<Credit, 'credit_type' | 'issued_credits' | 'expires_at'>;

const FormItem = Form.Item<AddCreditsFormValues>;

export const AddCreditsModal: FC<AddCreditsModalProps> = ({ accountId, isOpened, onClose }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const creditTypes = useCreditTypes();
  const addCredits = useAddCredits();

  return (
    <Modal
      title="Add Credits"
      open={isOpened}
      onClose={onClose}
      onCancel={onClose}
      okButtonProps={{
        loading: isProcessing,
        htmlType: 'submit',
      }}
      destroyOnClose
      modalRender={(dom) => (
        <Form
          disabled={isProcessing}
          labelCol={{ span: 6 }}
          onFinish={async (values) => {
            try {
              setIsProcessing(true);
              await addCredits.mutateAsync({
                account_id: accountId,
                ...values,
                expires_at: new Date(values.expires_at).toISOString(),
              });
              onClose();
            } finally {
              setIsProcessing(false);
            }
          }}
          clearOnDestroy
        >
          {dom}
        </Form>
      )}
    >
      <FormItem name="credit_type" label="Type">
        <Select>
          {creditTypes.map((creditType) => (
            <Select.Option key={creditType} value={creditType}>
              {creditType}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      <FormItem name="issued_credits" label="Credits">
        <InputNumber />
      </FormItem>
      <FormItem name="expires_at" label="Expiration Date">
        <Input type="date" />
      </FormItem>
    </Modal>
  );
};
