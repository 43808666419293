import { createFileRoute } from '@tanstack/react-router';
import { fallback, literal, number, object, optional, parse, string, union } from 'valibot';

import { PageLayout } from '@/components';

import { EnrichmentWrongReportsFilters, EnrichmentWrongReportsTable } from './-components/EnrichmentWrongReportsTable';

const FiltersSchema = object({
  size: fallback(number(), 20),
  page: fallback(optional(number(), 1), 1),
  start: fallback(optional(number(), 0), 0),
  sort_dir: fallback(optional(union([literal('desc'), literal('asc')]), 'desc'), 'desc'),
  sort_by: optional(string()),
  search: optional(string()),
  entity_type: optional(union([literal('email'), literal('phone')]), undefined),
});

export const Route = createFileRoute('/_authenticated/enrichment-wrong-reports/')({
  component: EnrichmentWrongInfo,
  validateSearch: (search): EnrichmentWrongReportsFilters => parse(FiltersSchema, search),
});

function EnrichmentWrongInfo() {
  const filters = Route.useSearch();
  const navigate = Route.useNavigate();

  const onFiltersChange = (params: EnrichmentWrongReportsFilters) => {
    navigate({ search: params });
  };

  return (
    <div>
      <PageLayout title={'Enrichment Wrong Reports'}>
        <EnrichmentWrongReportsTable filters={filters} onFiltersChange={onFiltersChange} />
      </PageLayout>
    </div>
  );
}
