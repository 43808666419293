import { useQuery } from '@tanstack/react-query';

import { useAppType } from '@/features/app';

import { getBillingAccountIdQueryKey } from '../keys';
import { BillingHttpClient } from '../libs';

export const useBillingAccountId = (email: string) => {
  const app = useAppType();
  return useQuery({
    queryKey: [getBillingAccountIdQueryKey(app, email)],
    queryFn: async () => {
      return BillingHttpClient.get<string>(app, `/api/v1/accounts/id?account_email=${email}`, {
        headers: {
          'X-Syncai-App-Id': app,
        },
      });
    },
  });
};
