import React from 'react';
import { Card, Flex, Input, Radio, Space, Table } from 'antd';

import { DateCell } from '@/components';

import { SortOrderApi } from '@/libs';

import { EnrichmentWrongReport } from '@/features/enrichment/types.ts';

import { useEnrichmentWrongReportsTableViewModel } from './hooks/useEnrichmentWrongReportsTableViewModel';

export type EnrichmentWrongReportsFilters = {
  size?: number;
  page?: number;
  start?: number;
  sort_by?: string;
  sort_dir?: SortOrderApi;
  search?: string;
  entity_type?: EnrichmentWrongReport['entity_type'];
};

type Props = {
  filters: EnrichmentWrongReportsFilters;
  onFiltersChange(filters: EnrichmentWrongReportsFilters): void;
};

const EnrichmentWrongReportColumn = Table.Column<EnrichmentWrongReport>;

export const EnrichmentWrongReportsTable: React.FC<Props> = ({ filters, onFiltersChange }) => {
  const { data, isLoading, pagination, onRadioGroupChange, onTableChange, onSearch } =
    useEnrichmentWrongReportsTableViewModel({
      filters,
      onFiltersChange,
    });

  return (
    <Card>
      <Flex vertical>
        <Space direction="horizontal" key="filter">
          <Input.Search
            placeholder="Search value, Linkedin ID..."
            allowClear
            enterButton="Search"
            key="input"
            defaultValue={filters.search}
            onSearch={onSearch}
          />
          <Radio.Group
            onChange={onRadioGroupChange}
            defaultValue={undefined}
            optionType="button"
            buttonStyle="solid"
            options={[
              { label: 'All', value: undefined },
              { label: 'Email', value: 'email' },
              { label: 'Phone', value: 'phone' },
            ]}
          />
        </Space>
        <Table
          dataSource={data}
          loading={isLoading}
          bordered
          scroll={{ x: 'max-content' }}
          rowKey={(record) => record.value}
          pagination={{ ...pagination, position: ['topLeft', 'bottomLeft'] }}
          onChange={onTableChange}
        >
          <EnrichmentWrongReportColumn dataIndex="value" key="value" title="Value" sorter />
          <EnrichmentWrongReportColumn dataIndex="linkedin_id" key="linkedin_id" title="Linkedin ID" sorter />
          <EnrichmentWrongReportColumn dataIndex="entity_type" key="entity_type" title="Entity Type" sorter />
          <EnrichmentWrongReportColumn dataIndex="counter" key="counter" title="Count" sorter />
          <EnrichmentWrongReportColumn
            dataIndex="created_at"
            key="created_at"
            title="Created At"
            sorter
            render={(_, item) => <DateCell date={item.created_at} format="localDate" />}
          />
          <EnrichmentWrongReportColumn
            dataIndex="updated_at"
            key="updated_at"
            title="Updated At"
            sorter
            render={(_, item) => <DateCell date={item.updated_at} format="localDate" />}
          />
        </Table>
      </Flex>
    </Card>
  );
};
