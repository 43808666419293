import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import { App as AntdApp, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

import { AuthProvider, useAuth } from '@/features/auth';

import { ErrorLayout, NotFoundLayout } from './components';
// Import the generated route tree
import { routeTree } from './routeTree.gen';

import './styles/normalize.scss';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

// Create a new router instance
const router = createRouter({
  routeTree,
  defaultNotFoundComponent: NotFoundLayout,
  defaultErrorComponent: ErrorLayout,
  defaultPreload: 'intent',
  context: {
    auth: undefined!,
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

// Render the app
const rootElement = document.getElementById('root')!;

export function App() {
  const auth = useAuth();

  return <RouterProvider router={router} context={{ auth }} />;
}

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <ConfigProvider>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools />
          <AuthProvider>
            <AntdApp>
              <App />
            </AntdApp>
          </AuthProvider>
        </QueryClientProvider>
      </ConfigProvider>
    </StrictMode>,
  );
}
