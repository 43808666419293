import { createFileRoute, Navigate, Outlet, redirect } from '@tanstack/react-router';
import { Spin } from 'antd';

import { AppLayout } from '@/components';

import { useAuth } from '@/features/auth';

export const Route = createFileRoute('/_authenticated')({
  beforeLoad: ({ context }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({ to: '/sign-in' });
    }
  },
  component: AuthenticatedLayout,
});

function AuthenticatedLayout() {
  const { isLoading, signOut, isAuthenticated, currentUser } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/sign-in" replace />;
  }

  if (isLoading || !currentUser) {
    return <Spin />;
  }

  return (
    <div>
      <AppLayout userEmail={currentUser?.email || ''} onLogout={signOut}>
        <Outlet />
      </AppLayout>
    </div>
  );
}
