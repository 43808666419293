import { createFileRoute } from '@tanstack/react-router';
import { App, Spin } from 'antd';

import { ErrorLayout, PageLayout } from '@/components';

import { useProduct, useUpdateProduct } from '@/features/billing';
import { ProductForm } from '@/features/billing/components/ProductForm';

export const Route = createFileRoute('/_authenticated/billing/products/$productId/')({
  component: ProductEdit,
});

const PageTitle = 'Edit Product';

export function ProductEdit() {
  const { message } = App.useApp();
  const params = Route.useParams();

  const { data: product, isLoading, isError } = useProduct(params.productId);
  const updateProduct = useUpdateProduct();

  if (isError) {
    return (
      <PageLayout title={PageTitle}>
        <ErrorLayout />
      </PageLayout>
    );
  }

  if (isLoading || !product) {
    return (
      <Spin spinning>
        <PageLayout title={PageTitle}></PageLayout>
      </Spin>
    );
  }

  return (
    <PageLayout title={PageTitle}>
      <ProductForm
        initialValues={product}
        onSubmit={async (updatedProduct) => {
          try {
            await updateProduct.mutateAsync(updatedProduct);
            message.success('Product updated successfully');
          } catch (error) {
            message.error('Failed to update product');
          }
        }}
      />
    </PageLayout>
  );
}
