import { useRef } from 'react';
import { RadioChangeEvent, TableProps } from 'antd';
import { SorterResult } from 'antd/es/table/interface';

import { SortOrderApi, SortOrderMapper } from '@/libs';

import { useEnrichmentWrongInfoReports } from '@/features/enrichment';

import { EnrichmentWrongReportsFilters } from '../index.tsx';

type SortFields = {
  sort_by: string;
  sort_dir: SortOrderApi;
};

const toSortParams = (sorter: SorterResult<unknown> | SorterResult<unknown>[]): SortFields => {
  const defaultSort: SortFields = {
    sort_by: 'created_at',
    sort_dir: 'desc',
  };

  const [sort] = Array.isArray(sorter) ? sorter : [sorter];

  if (!sort.order || !sort.field) return defaultSort;

  return {
    sort_by: sort.field as string,
    sort_dir: SortOrderMapper.toApi(sort.order),
  };
};

export const useEnrichmentWrongReportsTableViewModel = ({
  filters,
  onFiltersChange,
}: {
  filters: EnrichmentWrongReportsFilters;
  onFiltersChange(filters: EnrichmentWrongReportsFilters): void;
}) => {
  const { data: reports, isLoading } = useEnrichmentWrongInfoReports(filters);
  const dataTotal = useRef(0);

  if (reports?.length) {
    dataTotal.current = reports.length;
  }

  const onTableChange: TableProps['onChange'] = (pagination, _filters, sorter) => {
    onFiltersChange({
      ...filters,
      page: pagination.current || 1,
      start: (filters.size ?? 20) * ((pagination.current || 1) - 1),
      ...toSortParams(sorter),
    });
  };

  const onRadioGroupChange = (e: RadioChangeEvent) => {
    onFiltersChange({
      ...filters,
      page: 1,
      entity_type: e.target.value,
    });
  };

  const onSearch = (value: string) => {
    onFiltersChange({ ...filters, page: 1, search: value });
  };

  return {
    data: reports?.data,
    isLoading,
    pagination: {
      current: filters.page as number,
      pageSize: filters.size,
      total: dataTotal.current,
      showSizeChanger: false,
    },
    onRadioGroupChange,
    onTableChange,
    onSearch,
  };
};
