import { useQuery } from '@tanstack/react-query';

import { useAppType } from '@/features/app';

import { getProductTypesQueryKey } from '../keys';
import { BillingHttpClient } from '../libs';
import { ProductType } from '../types';

export const useProductTypes = () => {
  const appType = useAppType();

  return useQuery({
    queryKey: getProductTypesQueryKey(appType),
    queryFn: async () => {
      const productTypes = await BillingHttpClient.get<ProductType[]>(appType, '/api/v1/producttypes/');
      return productTypes?.filter((productType) => productType.app === appType) ?? [];
    },
  });
};
