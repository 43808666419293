import { getRouteApi, useNavigate } from '@tanstack/react-router';

const routeApi = getRouteApi('/_authenticated/blacklist/');

export const useBlackListViewModel = () => {
  const searchParams = routeApi.useSearch();
  const navigate = useNavigate();

  return {
    searchParams,
    navigate,
  };
};
