import React from 'react';
import { Modal } from 'antd';

import { ActionsCell } from '@/components';

import { PowerleadWorkspace } from '@/features/powerlead/workspace';

import type { WorkspaceFilters } from '../..';

import { useTableActionsCellViewModel } from './useTableActionsCellViewModel';

type Props = {
  item: PowerleadWorkspace;
  searchParams: WorkspaceFilters;
};

export const TableActionsCell: React.FC<Props> = ({ item, searchParams }) => {
  const { items, isModalOpen, toggleModal, onConfirmDeleteWorkspace } = useTableActionsCellViewModel({
    item,
    searchParams,
  });

  return (
    <>
      <ActionsCell items={items} />
      <Modal
        onOk={onConfirmDeleteWorkspace}
        okButtonProps={{ danger: true }}
        open={isModalOpen}
        onCancel={toggleModal}
        title={`Delete workspace for email - ${item.owner_email}?`}
      >
        <p>All data related to the workspace will be deleted</p>
      </Modal>
    </>
  );
};
