import { FC } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Table } from 'antd';

import { PowerleadAccount } from '@/features/powerlead/account';

import { useTableOnboardingCellViewModel } from './useTableOnboardingCellViewModel';

import styles from './styles.module.scss';

export const TableOnboardingCell: FC<{ item: PowerleadAccount }> = ({ item }) => {
  const { metadata } = useTableOnboardingCellViewModel(item);

  const columns = [
    {
      dataIndex: 'title',
      key: 'title',
      width: '190px',
    },
    {
      dataIndex: 'value',
      key: 'value',
      render: (value: boolean) =>
        value ? <CheckOutlined className={styles.green} /> : <CloseOutlined className={styles.red} />,
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={metadata.slice(0, 1)}
        pagination={false}
        showHeader={false}
        rowHoverable={false}
        size="small"
        tableLayout="fixed"
        className={styles.table}
        rowKey={(record) => record.title}
        expandable={{
          expandedRowRender: () => (
            <Table
              columns={columns}
              dataSource={metadata.slice(1)}
              pagination={false}
              showHeader={false}
              rowHoverable={false}
              size="small"
              tableLayout="fixed"
              className={`${styles.table} ${styles.tableNested}`}
              rowKey={(record) => record.title}
            />
          ),
        }}
      />
    </>
  );
};
