export class ApiUrl {
  static baseUrl = '/api';

  static getPowerleadUrl(endpoint: string) {
    return `/pw-api/api/v1${endpoint}`;
  }

  static getEnrichmentUrl(endpoint: string) {
    return `/enrichment-api/api/v1${endpoint}`;
  }
}
