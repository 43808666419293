import { createFileRoute } from '@tanstack/react-router';
import { fallback, literal, number, object, optional, parse, string, union } from 'valibot';

import { PageLayout } from '@/components';

import { AccountsTable } from './-components/AccountsTable';
import { AccountFilters } from './-components/AccountsTable/types';

const SearchSchema = object({
  page: fallback(optional(number(), 1), 1),
  start: fallback(optional(number(), 0), 0),
  length: fallback(optional(number(), 20), 20),
  sort_by: fallback(optional(string(), 'created_at'), 'created_at'),
  sort_dir: fallback(union([literal('desc'), literal('asc')]), 'desc'),
  search: fallback(optional(string()), undefined),
  workspace_id: fallback(optional(string()), undefined),
});

export const Route = createFileRoute('/_authenticated/accounts/')({
  component: Accounts,
  validateSearch: (search): AccountFilters => parse(SearchSchema, search),
});

function Accounts() {
  const searchParams = Route.useSearch();
  const navigate = Route.useNavigate();

  const onParamsChange = (params: AccountFilters) => {
    navigate({ search: params });
  };

  const clearSearchParams = (): void => {
    navigate({
      search: {
        page: 1,
        start: 0,
        length: 20,
        sort_by: 'created_at',
        sort_dir: 'desc',
      },
    });
  };

  return (
    <PageLayout title="Accounts">
      <AccountsTable filters={searchParams} onChangeFilters={onParamsChange} clearSearchParams={clearSearchParams} />
    </PageLayout>
  );
}
