import { getRouteApi, useNavigate } from '@tanstack/react-router';

import { SubscriptionTotalStatistic, useSubscriptionStatistic } from '@/features/billing';

const routeApi = getRouteApi('/_authenticated/billing/dashboard/');

const statisticMapByField = {
  trial_plan_count: {
    label: 'Trial plans',
  },
  free_plan_count: {
    label: 'Free plans',
  },
  monthly_plan_count: {
    label: 'Monthly plans',
  },
  annually_plan_count: {
    label: 'Annually plans',
  },
  bluesnap_subs_count: {
    label: 'Bluesnap subscriptions',
  },
  canceled_paid_subs_count: {
    label: 'Canceled subscriptions',
  },
  expired_paid_subs_count: {
    label: 'Expired subscriptions',
  },
};

export const useBillingDashboardViewModel = () => {
  const searchParams = routeApi.useSearch();
  const navigate = useNavigate();

  const { data, isError, isLoading } = useSubscriptionStatistic({ period: searchParams.period });

  const onChangePeriod = (period: 'week' | 'month' | 'year') => {
    navigate({ search: { period } });
  };

  const statisticItems = data
    ? Object.entries(statisticMapByField).map(([key, { label }]) => {
        return {
          label,
          value: (data?.[key as keyof SubscriptionTotalStatistic] || '') as string,
        };
      })
    : [];

  return {
    periodOptions: [
      { label: 'Week', value: 'week' },
      { label: 'Month', value: 'month' },
      { label: 'Year', value: 'year' },
    ],
    statisticItems,
    graphData: data?.graph_data || [],
    onChangePeriod,
    currentPeriod: searchParams.period || 'week',
    isLoading,
    isError,
  };
};
