import { FC, Fragment, useState } from 'react';
import { Button } from 'antd';

import { ProductTypeModal } from '../ProductTypeModal';

export const ProductTypeExtra: FC = () => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <Fragment>
      <ProductTypeModal isOpened={isOpened} onClose={() => setIsOpened(false)} variant="create" />
      <Button type="primary" onClick={() => setIsOpened(true)}>
        Create
      </Button>
    </Fragment>
  );
};
