import { useState } from 'react';

import { PowerleadEngagementStats } from '@/features/powerlead/report';
import { usePowerleadEngagementStats } from '@/features/powerlead/report/hooks/usePowerleadEngagementStats';

const selectItems = [
  {
    label: '1 Day',
    value: 1,
  },
  {
    label: '3 Days',
    value: 3,
  },
  {
    label: '1 Week',
    value: 7,
  },
  {
    label: '1 Month',
    value: 30,
  },
];

const chartConfigByField: Record<
  keyof PowerleadEngagementStats | string,
  {
    color: string;
    label: string;
  }
> = {
  inactive_accounts_count: {
    color: '#8884d8',
    label: 'Inactive Accounts',
  },
  search_count: {
    color: '#82ca9d',
    label: 'Searches total',
  },
  paid_search_count: {
    color: '#f50f2e',
    label: 'Paid Searches total',
  },
};

export const useEngagementStatsViewModel = () => {
  const [period, setPeriod] = useState(1);
  const { data, isError, isLoading } = usePowerleadEngagementStats({ days: period });

  return {
    selectItems,
    selectedItem: selectItems[0],
    onChnagePeriod: setPeriod,
    period,
    data: data
      ? Object.entries(data).map(([key, value]) => {
          return {
            entity: key,
            value,
            label: chartConfigByField[key].label,
            color: chartConfigByField[key].color,
          };
        })
      : [],
    isError,
    isLoading,
  };
};
