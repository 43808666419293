import { ExclamationCircleFilled } from '@ant-design/icons';
import { App, MenuProps, Modal } from 'antd';

import { type Credit, useDeleteCredit } from '@/features/billing';

export const useCreditActionsViewModel = (item: Credit) => {
  const { message } = App.useApp();
  const deleteCredit = useDeleteCredit();

  const items: MenuProps['items'] = [
    {
      key: 'delete',
      label: 'Delete',
      danger: true,
      onClick: async () => {
        Modal.confirm({
          title: 'Delete credit?',
          icon: <ExclamationCircleFilled />,
          onOk: async () => {
            try {
              await deleteCredit.mutateAsync(item.id);
            } catch (e) {
              message.error('Failed to delete credit');
            }
          },
        });
      },
    },
  ];
  return {
    items,
  };
};
