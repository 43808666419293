import { FC, useEffect, useState } from 'react';
import {
  AuditOutlined,
  BlockOutlined,
  ContainerOutlined,
  DollarOutlined,
  EyeInvisibleOutlined,
  FileTextOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ProductOutlined,
  ShoppingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Link, useLocation } from '@tanstack/react-router';
import { Button, Layout, Menu } from 'antd';
import { ItemType } from 'antd/es/menu/interface';

import styles from './styles.module.scss';

const navigation: ItemType[] = [
  {
    label: <Link to="/workspaces">Workspaces</Link>,
    key: '/workspaces',
    icon: <BlockOutlined />,
  },
  {
    label: <Link to="/accounts">Accounts</Link>,
    key: '/accounts',
    icon: <UserOutlined />,
  },
  {
    label: <Link to="/reports">Reports</Link>,
    key: '/reports',
    icon: <AuditOutlined />,
  },
  {
    label: (
      <Link to="/prospect-logs" search={{ view: 'all', page: 1 }}>
        Prospect Logs
      </Link>
    ),
    key: '/prospect-logs',
    icon: <ContainerOutlined />,
  },
  {
    label: (
      <Link to="/blacklist" search={{ page: 1 }}>
        Blacklist
      </Link>
    ),
    key: '/blacklist',
    icon: <EyeInvisibleOutlined />,
  },
  {
    label: 'Enrichment',
    key: '/enrichment',
    icon: <FileTextOutlined />,
    children: [
      {
        label: <Link to="/enrichment-csv">Enrichment CSV</Link>,
        key: '/enrichment-csv',
        icon: <FileTextOutlined />,
      },
      {
        label: <Link to="/enrichment-wrong-reports">Enrichment wrong info</Link>,
        key: '/enrichment-wrong-info',
        icon: <FileTextOutlined />,
      },
    ],
  },
  {
    label: 'Billing',
    key: '/billing',
    icon: <DollarOutlined />,
    children: [
      {
        label: (
          <Link to="/billing/purchases" search={{ limit: 50 }}>
            Purchases
          </Link>
        ),
        key: '/billing/purchases',
        icon: <ShoppingOutlined />,
      },
      {
        label: <Link to="/billing/product-types">Product types</Link>,
        key: '/billing/product-types',
        icon: <ProductOutlined />,
      },
      {
        label: <Link to="/billing/products">Products</Link>,
        key: '/billing/products',
        icon: <ProductOutlined />,
      },
    ],
  },
];

export const AppSider: FC = () => {
  const [collapsed, setCollapsed] = useState(() => Boolean(localStorage.getItem('sider-collapsed')));
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const location = useLocation();

  useEffect(() => {
    if (collapsed) {
      localStorage.setItem('sider-collapsed', '1');
    } else {
      localStorage.removeItem('sider-collapsed');
    }
  }, [collapsed]);

  useEffect(() => {
    setSelectedKeys([location.pathname]);
  }, [location.pathname]);

  const toggleSider = (): void => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      {/* First sider is used as not-fixed mask to change content width when collapsed */}
      <Layout.Sider className={styles.sider} width="300px" collapsible trigger={null} collapsed={collapsed} />
      <Layout.Sider className={styles.sider} width="300px" collapsible trigger={null} collapsed={collapsed}>
        <Button
          className={styles.collapseButton}
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={toggleSider}
        />
        <Menu mode="inline" items={navigation} selectedKeys={selectedKeys} className={styles.menu} />
      </Layout.Sider>
    </>
  );
};
