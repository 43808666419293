import { FC } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import type { ItemType } from 'antd/es/menu/interface';

type ActionsCellProps = {
  items: ItemType[];
};

export const ActionsCell: FC<ActionsCellProps> = ({ items }) => {
  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          Actions
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  );
};
