import { useQuery } from '@tanstack/react-query';

import { ApiUrl, httpClient } from '@/libs';

import { getPowerleadProspectLogQueryKey } from '../keys';
import { PowerleadProspectLog } from '../types';

export const usePowerleadProspectLogList = (params: Record<string, string | number>) => {
  return useQuery({
    queryKey: getPowerleadProspectLogQueryKey(params),
    queryFn: async () => {
      const reponse = await httpClient.get<{
        length: number;
        prospects: PowerleadProspectLog[];
      }>(ApiUrl.getPowerleadUrl('/admin/prospect_logs'), {
        params,
      });
      return reponse.data;
    },
  });
};
