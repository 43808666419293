import { FC, Fragment } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Flex, Form, Input, InputNumber, Modal, Select, Switch } from 'antd';
import { v4 as id } from 'uuid';

import type { ProductType, ProductTypeQuota } from '@/features/billing';

import { useProductTypeModalViewModel } from './useProductTypeModalViewModel';

type CreateProductTypeModalProps = {
  variant: 'create';
  isOpened: boolean;
  onClose: () => void;
};

type EditProductTypeModalProps = {
  variant: 'edit';
  isOpened: boolean;
  onClose: () => void;
  productType: ProductType;
};

type ProductTypeModalProps = CreateProductTypeModalProps | EditProductTypeModalProps;

const FormItem = Form.Item<ProductType>;
const QuotaFormItem = Form.Item<ProductTypeQuota[]>;
const FormName = 'product-type';

export const ProductTypeModal: FC<ProductTypeModalProps> = (props) => {
  const { title, isLoading, initialValues, onSubmit } = useProductTypeModalViewModel(props);

  return (
    <Fragment>
      <Modal
        title={title}
        onClose={props.onClose}
        open={props.isOpened}
        onCancel={props.onClose}
        okButtonProps={{
          htmlType: 'submit',
          disabled: isLoading,
        }}
        width={800}
        destroyOnClose
        modalRender={(dom) => (
          <Form<ProductType>
            disabled={isLoading}
            name={FormName}
            initialValues={initialValues}
            labelCol={{ span: 6 }}
            onFinish={onSubmit}
          >
            {dom}
          </Form>
        )}
      >
        <FormItem name="id" hidden>
          <Input hidden />
        </FormItem>
        <FormItem name="app" hidden>
          <Input hidden />
        </FormItem>
        <FormItem label="Label" name="label" rules={[{ required: true }]}>
          <Input />
        </FormItem>
        <Form.List name="quotas">
          {(fields, { add, remove }) => {
            return (
              <Flex gap={10} vertical>
                {fields.map((field) => (
                  <Card
                    key={field.key}
                    title={`Product Quota #${field.name + 1}`}
                    size="small"
                    extra={<DeleteOutlined onClick={() => remove(field.name)} />}
                  >
                    <QuotaFormItem name={[field.name, 'id']} hidden>
                      <Input hidden />
                    </QuotaFormItem>
                    <QuotaFormItem label="Type" name={[field.name, 'credit_type']}>
                      <Select>
                        <Select.Option value="basic">basic</Select.Option>
                        <Select.Option value="full">full</Select.Option>
                        <Select.Option value="scheduler">scheduler</Select.Option>
                        <Select.Option value="task">task</Select.Option>
                        <Select.Option value="note">note</Select.Option>
                        <Select.Option value="price_proposal">price_proposal</Select.Option>
                        <Select.Option value="tag_link">tag_link</Select.Option>
                        <Select.Option value="company">company</Select.Option>
                      </Select>
                    </QuotaFormItem>
                    <QuotaFormItem label="Credits" name={[field.name, 'credits']}>
                      <InputNumber min={0} />
                    </QuotaFormItem>
                    <QuotaFormItem label="Days" name={[field.name, 'duration_days']}>
                      <InputNumber min={0} />
                    </QuotaFormItem>
                    <QuotaFormItem label="Delayed start days" name={[field.name, 'delayed_start_days']}>
                      <InputNumber min={0} />
                    </QuotaFormItem>
                    <QuotaFormItem label="Renewable" name={[field.name, 'renewable']}>
                      <Switch />
                    </QuotaFormItem>
                  </Card>
                ))}
                <Button
                  onClick={() =>
                    add({
                      id: id(),
                      credit_type: 'basic',
                      credits: 0,
                      duration_days: 0,
                      delayed_start_days: 0,
                      renewable: true,
                    })
                  }
                >
                  Add Quota
                </Button>
              </Flex>
            );
          }}
        </Form.List>
      </Modal>
    </Fragment>
  );
};
