import { FC } from 'react';

import { ActionsCell } from '@/components';

import type { ProductType } from '@/features/billing';

import { ProductTypeModal } from '../../../ProductTypeModal';

import { useProductTypeActions } from './useProductTypeActionsViewModel';

export const ProductTypeActions: FC<{ productType: ProductType }> = ({ productType }) => {
  const { items, isEditModalOpened, handleEditModalClose } = useProductTypeActions({ productType });

  return (
    <>
      <ActionsCell items={items} />
      <ProductTypeModal
        variant="edit"
        productType={productType}
        isOpened={isEditModalOpened}
        onClose={handleEditModalClose}
      />
    </>
  );
};
