import { useQuery } from '@tanstack/react-query';

import { ApiUrl, httpClient } from '@/libs';

import { getPowerleadAccountsQueryKey } from '../keys';
import { PowerleadAccount } from '../types';

const API_URL = ApiUrl.getPowerleadUrl('/admin/accounts');

type PowerleadAccountsResult = {
  accounts: PowerleadAccount[];
  length: number;
};

const getPowerleadAccounts = async (params: Record<string, unknown>): Promise<PowerleadAccountsResult> => {
  const response = await httpClient.get<PowerleadAccountsResult>(API_URL, {
    params: params,
  });
  return response.data;
};

export const usePowerleadAccounts = (params: Record<string, unknown>) => {
  return useQuery<PowerleadAccountsResult, Error>({
    queryKey: getPowerleadAccountsQueryKey(params),
    queryFn: () => getPowerleadAccounts(params),
  });
};
