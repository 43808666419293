import { FC } from 'react';
import { Card, Col, Form, Row, Select, Space, Spin, Statistic, Typography } from 'antd';

import { StatsLineChart } from './components/StatsLineChart';
import { useTotalStatsViewModel } from './hooks/useTotalStatsViewModel';

import styles from './styles.module.scss';

export const TotalStats: FC = () => {
  const { current, previous, isLoading, isError, selectItems, selectedItem, onChnagePeriod } = useTotalStatsViewModel();
  return (
    <Card
      title="Total stats"
      extra={
        <Form.Item label="Select date range to compare" className={styles.formItem}>
          <Select
            className={styles.select}
            defaultValue={selectedItem.value}
            onChange={onChnagePeriod}
            options={selectItems}
          />
        </Form.Item>
      }
    >
      <Row>
        <Col span={12}>
          <Spin spinning={isLoading || isError}>
            <Space direction="vertical" className={styles.space}>
              <Typography>Previous: {selectedItem.label}</Typography>
              <Statistic title="New Accounts total:" value={previous.newAccountsTotal} />
              <StatsLineChart data={previous.statItems} />
            </Space>
          </Spin>
        </Col>
        <Col span={12}>
          <Spin spinning={isLoading || isError}>
            <Space direction="vertical" className={styles.space}>
              <Typography>Current: {selectedItem.label}</Typography>
              <Statistic title="New Accounts total:" value={current.newAccountsTotal} />
              <StatsLineChart data={current.statItems} />
            </Space>
          </Spin>
        </Col>
      </Row>
    </Card>
  );
};
