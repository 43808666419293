import React from 'react';
import { Card, Table } from 'antd';

import { PowerleadWorkspaceTeamMember } from '@/features/powerlead/workspace';

import styles from './styles.module.scss';

type Props = {
  data: PowerleadWorkspaceTeamMember[];
};

const TeamMembersColumn = Table.Column<PowerleadWorkspaceTeamMember>;

export const WorkspaceTeamMembersTable: React.FC<Props> = ({ data }) => {
  return (
    <Card title="Members">
      <Table pagination={false} dataSource={data} size="small" className={styles.nestedTable} bordered>
        <TeamMembersColumn dataIndex="uuid" key="uuid" title="ID" width="322px" />
        <TeamMembersColumn dataIndex="full_name" key="full_name" title="Full Name" width="155px" />
        <TeamMembersColumn dataIndex="email" key="email" title="Email" width="250px" />
        <TeamMembersColumn dataIndex="role" key="role" title="Role" width="70px" />
        <TeamMembersColumn
          dataIndex="invitation_status"
          key="invitation_status"
          title="Invitation status"
          width="130px"
        />
        <TeamMembersColumn
          dataIndex="invited_at"
          key="invited_at"
          title="Invited at"
          render={(_, item) => {
            if (!item.invited_at) return '-';

            return new Date(item.invited_at * 1000).toLocaleString();
          }}
        />
      </Table>
    </Card>
  );
};
