import { FC } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Space, Switch } from 'antd';

import { useAddItemButtonViewModel } from './hooks/useAddItemButtonViewModel';

import styles from './styles.module.scss';

export const AddItemButton: FC = () => {
  const { isModalVisible, onCloseModal, onClickButton, onSubmitForm, isPending } = useAddItemButtonViewModel();

  return (
    <>
      <Button type="primary" onClick={onClickButton}>
        Add Domain
      </Button>
      <Modal
        title="Add domains to blacklist"
        open={isModalVisible}
        onCancel={onCloseModal}
        onClose={onCloseModal}
        destroyOnClose
        footer={false}
      >
        <Form name="dynamicForm" onFinish={onSubmitForm} autoComplete="off">
          <Form.List name="domainOrRegx">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} align="baseline" className={styles.space} classNames={{ item: styles.spaceItem }}>
                    <Form.Item
                      {...restField}
                      label="Domain"
                      name={[name, 'value']}
                      rules={[{ required: true, message: 'Missing domain' }]}
                    >
                      <Input placeholder="Example: test.com" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add domain
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item name="notes" label="Notes:">
            <Input.TextArea placeholder="Notes" />
          </Form.Item>
          <Form.Item name="isRegex" label="Is Regex?">
            <Switch />
          </Form.Item>
          <Space className={styles.actions}>
            <Button type="primary" htmlType="submit" disabled={isPending}>
              Add
            </Button>
            <Button type="default" onClick={onCloseModal}>
              Cancel
            </Button>
          </Space>
        </Form>
      </Modal>
    </>
  );
};
