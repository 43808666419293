import { FC } from 'react';

import { ActionsCell, JsonModal } from '@/components';

import { PowerleadAccount } from '@/features/powerlead/account/types';

import { useTableActionsCellViewModel } from './useTableActionsCellViewModel';

export const TableActionsCell: FC<{ item: PowerleadAccount }> = ({ item }) => {
  const { items, visibleJsonViwer, setVisibleJsonViwer } = useTableActionsCellViewModel(item);

  return (
    <>
      <ActionsCell items={items} />
      <JsonModal json={item} isOpened={visibleJsonViwer} onClose={() => setVisibleJsonViwer(false)} />
    </>
  );
};
