import { useQuery } from '@tanstack/react-query';

import { ApiUrl, httpClient } from '@/libs';

import { getPowerleadEngagementStatsQueryKey } from '../keys';
import { PowerleadEngagementStats } from '../types';

const getEngagementStats = async (params: { days: number }): Promise<PowerleadEngagementStats> => {
  const resonse = await httpClient.get<PowerleadEngagementStats>(ApiUrl.getPowerleadUrl('/admin/engagement_stats'), {
    params,
  });
  return resonse.data;
};

export const usePowerleadEngagementStats = (params: { days: number }) => {
  return useQuery<PowerleadEngagementStats>({
    queryKey: getPowerleadEngagementStatsQueryKey(params),
    queryFn: () => getEngagementStats(params),
  });
};
