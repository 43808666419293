import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useAppType } from '@/features/app';

import { getProductTypeQueryKey, getProductTypesQueryKey } from '../keys';
import { BillingHttpClient } from '../libs';
import type { ProductType } from '../types';

export const useProductType = (id: ProductType['id']) => {
  const app = useAppType();
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: [getProductTypeQueryKey(app, id)],
    queryFn: async () => BillingHttpClient.get<ProductType>(app, `/api/v1/producttypes/${id}`),
    initialData: () => {
      const productTypes = queryClient.getQueryData<ProductType[]>(getProductTypesQueryKey(app));
      return productTypes?.find((productType) => productType.id === id);
    },
  });
};
