import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import type { AuthTokens } from '@/features/auth';
import { authTokenManager } from '@/features/auth/libs';

import { ApiUrl } from './ApiUrl';

interface HttpClientConstructor {
  url?: string;
}

type HttpClientAxiosConfig = Omit<import('axios').AxiosRequestConfig, 'baseURL'>;

class HttpClient {
  protected client: AxiosInstance;

  private isRefreshing = false;

  constructor(params?: HttpClientConstructor, config?: HttpClientAxiosConfig) {
    this.client = axios.create({
      baseURL: this.normalizeUrl(params?.url),
      ...this.applyAuthHeader(config),
    });

    this.client.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response && error.response.status === 401 && !this.isRefreshing) {
          try {
            this.isRefreshing = true;
            const newAccessToken = await this.refreshAccessToken();
            error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;
            return this.client(error.config);
          } finally {
            this.isRefreshing = false;
          }
        }
        return Promise.reject(error);
      },
    );
  }

  private async refreshAccessToken(): Promise<string> {
    const currentAuthTokens = authTokenManager.getAuthTokens();

    try {
      if (!currentAuthTokens || !currentAuthTokens.refresh) {
        throw new Error('No tokens to refresh');
      }

      const response = await this.client.post<AuthTokens>('/token/refresh/', { refresh: currentAuthTokens.refresh });
      authTokenManager.setAuthTokens(response.data);
      return response.data.access;
    } catch (error) {
      authTokenManager.removeAuthTokens();
      window.location.reload();
      throw error;
    }
  }

  private applyAuthHeader(config: HttpClientAxiosConfig = {}): HttpClientAxiosConfig {
    const authToken = authTokenManager.getAuthTokens();
    if (!authToken) return config;

    return Object.assign(config, {
      headers: {
        ...(config.headers || {}),
        Authorization: `Bearer ${authToken.access}`,
      },
    });
  }

  private normalizeUrl(url?: string) {
    return `${ApiUrl.baseUrl}${url ? '/' + url : ''}`;
  }

  get<ReturnType = unknown>(url: string, config?: AxiosRequestConfig) {
    return this.client.get<ReturnType>(url, this.applyAuthHeader(config));
  }

  post<ReturnType = unknown>(url: string, data?: unknown, config?: AxiosRequestConfig) {
    return this.client.post<ReturnType>(url, data, this.applyAuthHeader(config));
  }

  put<ReturnType = unknown>(url: string, data?: unknown, config?: AxiosRequestConfig) {
    return this.client.put<ReturnType>(url, data, this.applyAuthHeader(config));
  }

  patch<ReturnType = unknown>(url: string, data?: unknown, config?: AxiosRequestConfig) {
    return this.client.patch<ReturnType>(url, data, this.applyAuthHeader(config));
  }

  delete<ReturnType = unknown>(url: string, config?: AxiosRequestConfig) {
    return this.client.delete<ReturnType>(url, this.applyAuthHeader(config));
  }
}

export const httpClient = new HttpClient();
