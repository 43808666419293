import { FC } from 'react';
import { Typography } from 'antd';

import { useProductType } from '@/features/billing/hooks/useProductType';

export const ProductTypeCell: FC<{ product_type_id: string }> = ({ product_type_id }) => {
  const { data } = useProductType(product_type_id);

  return <Typography.Text>{data?.label}</Typography.Text>;
};
