import type { GetProps, PaginationProps, TableProps } from 'antd';
import { DatePicker } from 'antd';

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

import { Dispatch, SetStateAction, useRef, useState } from 'react';
import dayjs from 'dayjs';

import { useProducts } from '@/features/billing';
import { PowerleadProspectLog, usePowerleadProspectLogList } from '@/features/powerlead/prospectLog';

import { ProspectLogTableFilters } from '../types';

const filterTypeOptions = [
  {
    value: 'spt_keywords_filter',
    label: 'spt_keywords_filter',
  },
  {
    value: 'wrong_info_filter',
    label: 'wrong_info_filter',
  },
  {
    value: 'followers_email_filter',
    label: 'followers_email_filter',
  },
  {
    value: 'followers_phone_filter',
    label: 'followers_phone_filter',
  },
  {
    value: 'gdpr_countries_phone_filter',
    label: 'gdpr_countries_phone_filter',
  },
];

const ROWS_PER_PAGE = 20;

export const useProspectLogTable = ({
  filters,
  onFilterChange,
}: {
  filters: ProspectLogTableFilters;
  onFilterChange: (filters: ProspectLogTableFilters) => void;
}): {
  dataSource: PowerleadProspectLog[];
  pagination: PaginationProps;
  isLoading: boolean;
  isError: boolean;
  values: { fromTo: RangePickerProps['value']; planType: string; dataFilters: string[]; domain: string };
  planTypeOptions: { value: string; label: string }[] | undefined;
  filterTypeOptions: { value: string; label: string }[];
  activeItem: [string | null, Dispatch<SetStateAction<string | null>>];
  onChangeRangePicker: (range: RangePickerProps['value']) => void;
  onChangePlanType: (value: string) => void;
  onChangeFilterType: (value: string[]) => void;
  onChangeDomain: (value: string) => void;
  onResetFilters: () => void;
  onTableChange: TableProps['onChange'];
} => {
  const dataTotal = useRef(0);
  const activeItem = useState<string | null>(null);
  const { data: products } = useProducts();
  const { data, isLoading, isError } = usePowerleadProspectLogList({
    ...(filters.from_dt && { from_dt: filters.from_dt / 1000 }),
    ...(filters.to_dt && { to_dt: filters.to_dt / 1000 }),
    ...(filters.plan_type && { plan_type: filters.plan_type }),
    ...(filters.domain && { domain: filters.domain }),
    ...(filters.data_filters && { data_filters: filters.data_filters }),
    [filters.view]: 1,
    start: filters.page ? ROWS_PER_PAGE * (filters.page - 1) : 0,
    length: 20,
  });

  if (data?.length !== undefined) {
    dataTotal.current = data.length;
  }

  const onResetFilters = () => {
    onFilterChange({ view: filters.view, page: 1 });
  };

  const onChangeRangePicker = (range: RangePickerProps['value']) => {
    if (range?.[0] && range?.[1]) {
      onFilterChange({ ...filters, from_dt: range[0].unix() * 1000, to_dt: range[1].unix() * 1000, page: 1 });
    } else {
      const copyFilters = { ...filters, page: 1 };
      delete copyFilters.from_dt;
      delete copyFilters.to_dt;
      onFilterChange(copyFilters);
    }
  };

  const onChangePlanType = (value: string) => {
    if (value) {
      onFilterChange({ ...filters, plan_type: value });
    } else {
      const copyFilters = { ...filters };
      delete filters.plan_type;
      onFilterChange(copyFilters);
    }
  };

  const onChangeFilterType = (value: string[]) => {
    if (value) {
      onFilterChange({ ...filters, data_filters: value.join(',') });
    } else {
      const copyFilters = { ...filters };
      delete copyFilters.data_filters;
      onFilterChange(copyFilters);
    }
  };

  const onChangeDomain = (value: string) => {
    if (value) {
      onFilterChange({ ...filters, domain: value });
    } else {
      const copyFilters = { ...filters };
      delete copyFilters.domain;
      onFilterChange(copyFilters);
    }
  };

  const onTableChange: TableProps['onChange'] = (pagination) => {
    onFilterChange({ ...filters, page: pagination.current || 1 });
  };

  return {
    dataSource: data?.prospects || [],
    pagination: {
      current: filters.page || 1,
      pageSize: ROWS_PER_PAGE,
      total: dataTotal.current,
      showSizeChanger: false,
    },
    isLoading,
    isError,
    values: {
      fromTo: filters?.from_dt && filters?.to_dt ? [dayjs(filters.from_dt), dayjs(filters.to_dt)] : undefined,
      planType: filters.plan_type ?? '',
      dataFilters: filters.data_filters ? filters.data_filters.split(',') : [],
      domain: filters.domain ?? '',
    },
    onChangeRangePicker,
    onChangePlanType,
    onChangeFilterType,
    onChangeDomain,
    onResetFilters,
    onTableChange,
    activeItem,
    planTypeOptions: products?.map((product) => ({ value: product.id, label: product.label })) ?? [],
    filterTypeOptions: filterTypeOptions,
  };
};
