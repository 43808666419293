import { FC } from 'react';
import ReactJson from 'react-json-view';
import { Modal } from 'antd';

type JsonModalProps = {
  json: object;
  isOpened: boolean;
  onClose: () => void;
};

export const JsonModal: FC<JsonModalProps> = ({ json, isOpened, onClose }) => {
  return (
    <Modal title="JSON" width="60%" open={isOpened} onClose={onClose} onCancel={onClose} footer={false} destroyOnClose>
      <ReactJson src={json} />
    </Modal>
  );
};
