import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAppType } from '@/features/app';

import { getCreditsQueryKey } from '../keys';
import { BillingHttpClient } from '../libs';

export const useDeleteCredit = () => {
  const app = useAppType();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (creditId: string) => {
      return BillingHttpClient.delete(app, `/api/v1/credits/${creditId}`, {
        headers: {
          'X-Syncai-App-Id': app,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getCreditsQueryKey(app),
      });
    },
  });
};
