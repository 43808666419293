import { FC, useMemo } from 'react';
import { Typography } from 'antd';

type DateFormat = 'iso' | 'utc' | 'date' | 'time' | 'local' | 'localDate' | 'localTime' | Intl.DateTimeFormatOptions;

type DateCellProps = {
  date: string | number | Date;
  format?: DateFormat;
  copyFormat?: DateFormat;
};

const formatDate = (date: Date, format: DateFormat): string => {
  if (typeof format === 'object') {
    return date.toLocaleString(undefined, format);
  }

  switch (format) {
    case 'iso':
      return date.toISOString();
    case 'utc':
      return date.toUTCString();
    case 'date':
      return date.toDateString();
    case 'time':
      return date.toTimeString();
    case 'local':
      return date.toLocaleString();
    case 'localDate':
      return date.toLocaleDateString();
    case 'localTime':
      return date.toLocaleTimeString();
    default:
      return date.toISOString();
  }
};

export const DateCell: FC<DateCellProps> = ({ date, format, copyFormat }) => {
  const dateObj = useMemo(() => {
    if (typeof date === 'string') {
      return new Date(date);
    } else if (typeof date === 'number') {
      return new Date(date * 1000);
    } else {
      return date;
    }
  }, [date]);

  const formattedDate = useMemo(() => {
    return formatDate(dateObj, format ?? 'local');
  }, [dateObj, format]);

  return (
    <Typography.Text
      copyable={{
        text: () => formatDate(dateObj, copyFormat ?? 'iso'),
      }}
    >
      {formattedDate}
    </Typography.Text>
  );
};
