import { FC } from 'react';
import { Button } from 'antd';

import { useDeleteButtonItemViewModel } from './hooks/useDeleteButtonItemViewModel';

export const DeleteItemButton: FC<{ uuid: string }> = ({ uuid }) => {
  const { onClick } = useDeleteButtonItemViewModel(uuid);

  return (
    <Button type="primary" onClick={onClick}>
      Delete
    </Button>
  );
};
