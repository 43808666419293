import { FC } from 'react';
import { Button, Card, Col, Row, Statistic } from 'antd';

import { useCollaborationStatsViewModel } from './hooks/useCollaborationStatsViewModel';

import styles from './styles.module.scss';

export const CollaborationStats: FC = () => {
  const { data, onExport, isLoading, contextHolder } = useCollaborationStatsViewModel();

  return (
    <Card
      title="Collaboration stats"
      extra={[
        <Button key="action" type="primary" disabled={!data} onClick={onExport}>
          Export
        </Button>,
      ]}
    >
      {contextHolder}
      <Row className={styles.row}>
        {data.map((item) => {
          return (
            <Col span={6} key={item.label}>
              <Statistic title={item.label} value={item.value} loading={isLoading} />
            </Col>
          );
        })}
      </Row>
    </Card>
  );
};
