import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAppType } from '@/features/app';

import { getCreditsQueryKey } from '../keys';
import { BillingHttpClient } from '../libs';
import { Credit } from '../types';

type AddCreditsData = Pick<Credit, 'account_id' | 'credit_type' | 'expires_at' | 'issued_credits'>;

export const useAddCredits = () => {
  const app = useAppType();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: AddCreditsData) =>
      BillingHttpClient.post(
        app,
        '/api/v1/credits/',
        { ...data, issuer: 'admin' },
        {
          headers: {
            'X-Syncai-App-Id': app,
          },
        },
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getCreditsQueryKey(app),
      });
    },
  });
};
