import { FC } from 'react';
import { Card, Flex, Input, Space, Table } from 'antd';

import { BooleanCell, DateCell } from '@/components';

import { PowerleadDomainBlacklistItem } from '@/features/powerlead/blacklist';

import { AddItemButton } from './components/AddItemButton';
import { DeleteItemButton } from './components/DeleteItemButton';
import { useBlackListTableViewModel } from './hooks/useBlackListTableViewModel';

const TableColumn = Table.Column<PowerleadDomainBlacklistItem>;

type Filters = {
  page: number;
  search?: string;
};

type BlacklistTableProps = {
  filters: Filters;
  onFilterChange: (filters: Filters) => void;
};

export const BlacklistTable: FC<BlacklistTableProps> = (props) => {
  const { dataSource, isLoading, isError, onChnageTable, pagination, onSearch } = useBlackListTableViewModel(props);
  return (
    <Card title="Domains" extra={<AddItemButton />}>
      <Space direction="horizontal" key="filter">
        <Input.Search
          placeholder="Search email"
          onSearch={onSearch}
          allowClear
          enterButton="Search"
          key="input"
          defaultValue={props.filters.search ?? ''}
        />
      </Space>
      <Table
        onChange={onChnageTable}
        dataSource={dataSource}
        pagination={{ ...pagination, showSizeChanger: false, position: ['topLeft', 'bottomLeft'] }}
        loading={isLoading || isError}
        key="table"
        bordered
      >
        <TableColumn title="ID" dataIndex="uuid" key="uuid" />
        <TableColumn title="Domain" dataIndex="domain" key="domain" />
        <TableColumn title="Notes" dataIndex="notes" key="notes" />
        <TableColumn
          title="Is Regex"
          dataIndex="is_regex"
          key="is_regex"
          render={(_, row) => {
            return <BooleanCell value={row.is_regex} />;
          }}
        />
        <TableColumn
          title="Updated at"
          dataIndex="updated_at"
          key="updated_at"
          render={(_, row) => {
            return <DateCell date={row.updated_at} />;
          }}
        />
        <TableColumn
          width={100}
          title=""
          dataIndex="actions"
          key="actions"
          render={(_, row) => {
            return (
              <Flex justify="end">
                <DeleteItemButton uuid={row.domain} />
              </Flex>
            );
          }}
        />
      </Table>
    </Card>
  );
};
