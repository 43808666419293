import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiUrl, httpClient } from '@/libs';

import { getPowerleadDomainBlacklistStaticQueryKey } from '../keys';

export const usePowerleadDomainBlacklistAdd = () => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: async (body: { domains: string[]; notes?: string; is_regex?: boolean }) => {
      const response = await httpClient.post(ApiUrl.getPowerleadUrl('/admin/add-blacklisted-domains'), body);
      return response.data;
    },
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: getPowerleadDomainBlacklistStaticQueryKey(),
      });
    },
  });
};
