import { createFileRoute } from '@tanstack/react-router';
import { Layout } from 'antd';

import { SignInForm } from './-components';

export const Route = createFileRoute('/_unauthenticated/sign-in/')({
  component: SignInPage,
});

function SignInPage() {
  return (
    <Layout
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <SignInForm />
    </Layout>
  );
}
