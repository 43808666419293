import { useAuth } from '@/features/auth';

export type FormValues = {
  username: string;
  password: string;
};

export function useSignInForm() {
  const { signIn, isLoading } = useAuth();

  return {
    isLoading,
    onSubmit: signIn,
  };
}
