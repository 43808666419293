import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiUrl, httpClient } from '@/libs';

import { getPowerleadEnrichmentCsvStaticQueryKey } from '../keys';

export const usePowerleadDeleteEnrichmentCsv = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (uuid: string): Promise<boolean> => {
      const response = await httpClient.delete<boolean>(
        ApiUrl.getPowerleadUrl('/csv-enrichment-admin/:uuid').replace(':uuid', uuid),
      );
      return response.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: getPowerleadEnrichmentCsvStaticQueryKey() });
    },
  });
};
