import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAppType } from '@/features/app';

import { getProductTypesQueryKey } from '../keys';
import { BillingHttpClient } from '../libs';
import type { ProductType } from '../types';

export const useUpdateProductType = () => {
  const appType = useAppType();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (productType: ProductType) => {
      return BillingHttpClient.put<ProductType>(appType, `/api/v1/producttypes/${productType.id}`, productType);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getProductTypesQueryKey(appType) });
    },
  });
};
