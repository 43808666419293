import { FC, Fragment } from 'react';

import { ActionsCell, JsonModal } from '@/components';

import type { Product } from '@/features/billing';

import { useProductActionsViewModel } from './useProductActionsViewModel';

export const ProductActions: FC<{ product: Product }> = ({ product }) => {
  const { items, isJsonModalOpened, closeJsonModal } = useProductActionsViewModel({ product });

  return (
    <Fragment>
      <ActionsCell items={items} />
      <JsonModal json={product} isOpened={isJsonModalOpened} onClose={closeJsonModal} />
    </Fragment>
  );
};
