import type { PurchaseType } from '../types';

const purchaseTypes: ReadonlyArray<PurchaseType> = Object.freeze([
  'gift',
  'bluesnap',
  'stripe',
  'app_store',
  'google_play',
]);

export const usePurchaseTypes = (): ReadonlyArray<PurchaseType> => {
  return purchaseTypes;
};
