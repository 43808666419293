import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useAppType } from '@/features/app';

import { getCreditsQueryKey } from '../keys';
import { BillingHttpClient } from '../libs';
import type { Credit } from '../types';

type CreditsFilters = {
  account_id: string;
  limit: number;
  skip?: number;
};

export const useCredits = (filters: CreditsFilters) => {
  const app = useAppType();

  const searchParams = useMemo(() => {
    const sp = new URLSearchParams();
    sp.set('app', app);
    sp.set('account_id', filters.account_id);
    if (filters.limit) sp.set('limit', filters.limit.toString());
    if ('skip' in filters && filters.skip != undefined) sp.set('skip', filters.skip.toString());
    return sp;
  }, [app, filters]);

  return useQuery({
    enabled: filters.account_id !== '',
    queryKey: getCreditsQueryKey(app, searchParams),
    queryFn: async () => {
      return BillingHttpClient.get<{
        items: Credit[];
        total: number;
      }>(app, `/api/v1/credits/?${searchParams.toString()}`, {
        headers: {
          'X-Syncai-App-Id': app,
        },
      });
    },
  });
};
