import { PowerleadAccount } from '@/features/powerlead/account';

type OnboardingKey = keyof PowerleadAccount['settings']['onboarding'];
type OnboardingMetadata = { title: string };

const OnboardingStepsMetadata: Record<OnboardingKey, OnboardingMetadata> = {
  is_contact_exported: {
    title: 'Contact exported',
  },
  is_contact_revealed: {
    title: 'Contact revealed',
  },
  is_extension_installed: {
    title: 'Extension installed',
  },
  is_team_member_invited: {
    title: 'Team member invited',
  },
  is_clicked_download_extension: {
    title: 'Download extension clicked',
  },
  is_signup_complete: {
    title: 'Signup complete',
  },
  is_viewed_dashboard: {
    title: 'Dashboard viewed',
  },
  is_viewed_install_extension_page: {
    title: 'Install extension page viewed',
  },
};

export const useTableOnboardingCellViewModel = (item: PowerleadAccount) => {
  return {
    metadata: Object.entries(item.settings.onboarding)
      .filter(([key]) => key in OnboardingStepsMetadata)
      .map(([key, value]) => ({
        title: OnboardingStepsMetadata[key as OnboardingKey].title,
        value,
      })),
  };
};
