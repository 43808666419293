import { useQuery } from '@tanstack/react-query';

import { ApiUrl, httpClient } from '@/libs';

import { getPowerleadEnrichmentCsvStaticQueryKey } from '../keys';
import { PowerleadEnrichmentCsvItem } from '../types';

const getEnrichmentCsvList = async (): Promise<PowerleadEnrichmentCsvItem[]> => {
  const response = await httpClient.get<PowerleadEnrichmentCsvItem[]>(ApiUrl.getPowerleadUrl('/csv-enrichment-admin'));
  return response.data;
};

export const usePowerleadEnrichmentCsvList = () => {
  return useQuery({ queryKey: getPowerleadEnrichmentCsvStaticQueryKey(), queryFn: getEnrichmentCsvList });
};
