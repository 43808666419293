import { App } from 'antd';

import { usePowerleadDomainBlacklistDelete } from '@/features/powerlead/blacklist';

export const useDeleteButtonItemViewModel = (uuid: string) => {
  const { modal, message } = App.useApp();
  const { mutateAsync } = usePowerleadDomainBlacklistDelete();

  const onClick = () => {
    modal.confirm({
      title: 'Are you sure you want to delete this item?',
      onOk: async () => {
        try {
          await mutateAsync(uuid);
          message.success(`${uuid} domain has been deleted`);
        } catch (error) {
          message.error(`Failed to delete ${uuid} domain`);
        }
      },
    });
  };

  return {
    onClick,
  };
};
