import { useQuery } from '@tanstack/react-query';

import { useAppType } from '@/features/app';

import { getProductsQueryKey } from '../keys';
import { BillingHttpClient } from '../libs';
import { Product } from '../types';

export const useProducts = () => {
  const app = useAppType();

  return useQuery({
    queryKey: getProductsQueryKey(app),
    queryFn: async () => {
      const products = await BillingHttpClient.get<Product[]>(app, '/api/v1/products/');
      return products?.filter((product) => product.app === app) ?? [];
    },
  });
};
