import { useQuery } from '@tanstack/react-query';

import { ApiUrl, httpClient } from '@/libs';

import { getPowerleadCollaborationStatsStaticQueryKey } from '../keys';
import { PowerleadCollaborationStats } from '../types';

const getCollaborationStats = async (): Promise<PowerleadCollaborationStats> => {
  const response = await httpClient.get<PowerleadCollaborationStats>(
    ApiUrl.getPowerleadUrl('/admin/collaboration_stats'),
  );
  return response.data;
};

export const usePowerleadCollaboratioStats = () => {
  return useQuery<PowerleadCollaborationStats>({
    queryKey: getPowerleadCollaborationStatsStaticQueryKey(),
    queryFn: getCollaborationStats,
  });
};
