import { App } from 'antd';

import { useAppType } from '@/features/app';
import { ProductType, useCreateProductType, useUpdateProductType } from '@/features/billing';

type Params =
  | {
      variant: 'create';
      onClose: () => void;
    }
  | {
      variant: 'edit';
      productType: ProductType;
      onClose: () => void;
    };

export const useProductTypeModalViewModel = (params: Params) => {
  const { message } = App.useApp();
  const create = useCreateProductType();
  const update = useUpdateProductType();
  const app = useAppType();

  const mutator = params.variant === 'create' ? create : update;
  const isLoading = mutator.isPending;

  const title = params.variant === 'create' ? 'Create Product Type' : 'Edit Product Type';

  const initialValues = params.variant === 'edit' ? params.productType : {};

  const onSubmit = async (productType: ProductType): Promise<void> => {
    try {
      if (params.variant === 'create') {
        await create.mutateAsync({
          ...productType,
          app,
        });
        message.success('Successfully created product type');
      } else {
        await update.mutateAsync({
          ...productType,
          id: params.productType.id,
          app: params.productType.app,
        });
        message.success('Successfully updated product type');
      }
      params.onClose();
    } catch {
      message.error(`Failed to ${params.variant} product type`);
    }
  };

  return {
    title,
    onSubmit,
    isLoading,
    initialValues,
  };
};
