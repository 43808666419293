import { QueryKey } from '@tanstack/react-query';

export const getPowerleadCollaborationStatsStaticQueryKey = (): QueryKey => ['collaboration_stats'];
export const getPowerleadTotalStatsStaticQueryKey = (): QueryKey => ['total_stats'];
export const getPowerleadEngagementStatsStaticQueryKey = (): QueryKey => ['engagement_stats'];

export const getPowerleadTotalStatsQueryKey = (params: { from_dt: number; to_dt: number }): QueryKey => [
  ...getPowerleadTotalStatsStaticQueryKey(),
  params,
];

export const getPowerleadEngagementStatsQueryKey = (params: { days: number }): QueryKey => [
  ...getPowerleadEngagementStatsStaticQueryKey(),
  params,
];
