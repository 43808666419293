import { FC } from 'react';
import { Card, Table } from 'antd';

import { ErrorLayout } from '@/components';

import { type Product, useProducts } from '@/features/billing';

import { ProductActions } from './components/ProductActions';
import { ProductTypeCell } from './components/ProductTypeCell';

const ProductColumn = Table.Column<Product>;

export const ProductsTable: FC = () => {
  const { data: dataSource, isError, isLoading } = useProducts();

  if (isError) return <ErrorLayout />;

  return (
    <Card>
      <Table
        dataSource={dataSource}
        loading={isLoading}
        rowKey="id"
        pagination={{
          pageSize: 25,
        }}
        bordered
      >
        <ProductColumn dataIndex="label" title="Label" />
        <ProductColumn dataIndex="stripe_product_id" title="Stripe ID" />
        <ProductColumn
          dataIndex="product_type_id"
          title="Product"
          render={(id) => <ProductTypeCell product_type_id={id} />}
        />
        <ProductColumn dataIndex="id" title="Actions" render={(_, product) => <ProductActions product={product} />} />
      </Table>
    </Card>
  );
};
