import { useRef } from 'react';
import { TableProps } from 'antd';

import { usePowerleadDomainBlacklist } from '@/features/powerlead/blacklist';

const ROWS_PER_PAGE = 20;

type Filters = {
  page: number;
  search?: string;
};

export const useBlackListTableViewModel = (params: {
  filters: Filters;
  onFilterChange: (filters: Filters) => void;
}) => {
  const totalDataSource = useRef(0);
  const { data, isError, isLoading } = usePowerleadDomainBlacklist({
    start: (params.filters.page - 1) * ROWS_PER_PAGE,
    search: params.filters.search,
    length: ROWS_PER_PAGE,
  });
  if (data) {
    totalDataSource.current = data.length;
  }

  const onChnageTable: TableProps['onChange'] = (pagination) => {
    params.onFilterChange({
      ...params.filters,
      page: pagination.current || 1,
    });
  };

  const onSearch = (value: string) => {
    params.onFilterChange({
      ...params.filters,
      search: value,
    });
  };

  return {
    dataSource: data?.domains || [],
    isError,
    isLoading,
    onChnageTable,
    onSearch,
    pagination: {
      current: params.filters.page,
      pageSize: ROWS_PER_PAGE,
      total: totalDataSource.current,
    },
  };
};
