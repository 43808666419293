import { FC, useRef } from 'react';
import { Card, Table } from 'antd';

import { BooleanCell, DateCell } from '@/components';

import { getPage, getSkip } from '@/libs/pagination';

import { type Credit, useCredits } from '@/features/billing';

import { CreditActions } from './components/CreditActions';
import { ProductCell } from './components/ProductCell';
import type { CreditsTableFilters } from './types';

type CreditsTableProps = {
  filters: CreditsTableFilters;
  onFiltersChange: (filters: CreditsTableFilters) => void;
};

const CreditColumn = Table.Column<Credit>;

export const CreditsTable: FC<CreditsTableProps> = ({ filters, onFiltersChange }) => {
  const totalRef = useRef(0);
  const credits = useCredits(filters);

  if (credits.data?.total !== undefined) {
    totalRef.current = credits.data.total;
  }

  return (
    <Card>
      <Table
        dataSource={credits.data?.items ?? []}
        onChange={(pagination) =>
          onFiltersChange({
            ...filters,
            skip: getSkip({
              limit: pagination.pageSize ?? filters.limit,
              page: pagination.current ?? 0,
            }),
          })
        }
        loading={credits.isLoading}
        bordered
        pagination={{
          pageSize: filters.limit,
          total: totalRef.current,
          current: getPage(filters),
          position: ['bottomLeft', 'topLeft'],
        }}
      >
        <CreditColumn dataIndex="credit_type" title="Credit type" key="credit_type" />
        <CreditColumn
          dataIndex="product_id"
          title="Product"
          key="product_id"
          render={(id) => <ProductCell id={id} />}
        />
        <CreditColumn dataIndex="issued_credits" title="Issued credits" key="issued_credits" />
        <CreditColumn dataIndex="used_credits" title="Used credits" key="used_credits" />
        <CreditColumn dataIndex="issuer" title="Issuer" key="issuer" />
        <CreditColumn
          dataIndex="created_at"
          title="Created at"
          key="created_at"
          render={(date) => <DateCell date={date} />}
        />
        <CreditColumn
          dataIndex="expires_at"
          title="Expires at"
          key="expires_at"
          render={(date) => <DateCell date={date} />}
        />
        <CreditColumn
          dataIndex="is_active"
          title="Is active"
          key="is_active"
          render={(isActive) => <BooleanCell value={isActive} />}
        />
        <CreditColumn dataIndex="id" title="Actions" key="id" render={(_, credit) => <CreditActions item={credit} />} />
      </Table>
    </Card>
  );
};
