import { Button, Form, Input } from 'antd';

import { FormValues, useSignInForm } from './useSignInForm';

const FormItem = Form.Item<FormValues>;

export function SignInForm() {
  const { onSubmit, isLoading } = useSignInForm();

  return (
    <Form
      name="sign-in"
      autoComplete="off"
      style={{ width: 600 }}
      labelCol={{ span: 6 }}
      onFinish={onSubmit}
      disabled={isLoading}
    >
      <FormItem label="Email" name="username" rules={[{ required: true, message: 'Please input your email!' }]}>
        <Input />
      </FormItem>
      <FormItem label="Password" name="password" rules={[{ required: true, message: 'Please input your password' }]}>
        <Input.Password />
      </FormItem>

      <FormItem wrapperCol={{ offset: 6 }}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Submit
        </Button>
      </FormItem>
    </Form>
  );
}
