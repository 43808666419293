import { useQuery } from '@tanstack/react-query';

import { ApiUrl, httpClient } from '@/libs';

import { getPowerleadDomainBlacklistQueryKey } from '../keys';
import { PowerleadDomainBlacklistItem } from '../types';

export const usePowerleadDomainBlacklist = (params: { start: number; length: number; search?: string }) => {
  return useQuery({
    queryKey: getPowerleadDomainBlacklistQueryKey(params),
    queryFn: async (): Promise<{
      domains: PowerleadDomainBlacklistItem[];
      length: number;
      search?: string;
    }> => {
      const response = await httpClient.get<{
        domains: PowerleadDomainBlacklistItem[];
        length: number;
      }>(ApiUrl.getPowerleadUrl('/admin/blacklist-email-domain'), { params });
      return response.data;
    },
  });
};
