import type { QueryKey } from '@tanstack/react-query';

import type { AppType } from '@/features/app';

export const getProductTypesQueryKey = (appType: AppType): QueryKey => [appType, 'product-types'];

export const getProductTypeQueryKey = (appType: AppType, productTypeId: string): QueryKey => [
  appType,
  'product-type',
  productTypeId,
];

export const getProductsQueryKey = (appType: AppType): QueryKey => [appType, 'products'];

export const getProductQueryKey = (appType: AppType, productId: string): QueryKey => [appType, 'product', productId];

export const getPurhcasesQueryKey = (appType: AppType, searchParams?: URLSearchParams): QueryKey => {
  const baseKey = [appType, 'purchases'];

  if (searchParams) {
    baseKey.push(searchParams.toString());
  }

  return baseKey;
};

export const getSubscriptionStatisticQueryKey = (
  appType: AppType,
  params: { period: 'year' | 'month' | 'week' },
): QueryKey => [appType, 'subscriptions-statistic', params];

export const getBillingAccountIdQueryKey = (appType: AppType, email: string): QueryKey => [
  appType,
  'billing-account-id',
  email,
];

export const getCreditsQueryKey = (appType: AppType, searchParams?: URLSearchParams): QueryKey => {
  const baseKey = [appType, 'credits'];

  if (searchParams) {
    baseKey.push(searchParams.toString());
  }

  return baseKey;
};
