import { createFileRoute } from '@tanstack/react-router';

import { PageLayout } from '@/components';

import { EnrichmentTable } from './-components/EnrichmentTable';

export const Route = createFileRoute('/_authenticated/enrichment-csv/')({
  component: EnrichmentCsv,
});

function EnrichmentCsv() {
  return (
    <PageLayout title="Enrichment CSV">
      <EnrichmentTable />
    </PageLayout>
  );
}
