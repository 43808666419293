import { FC, PropsWithChildren } from 'react';
import { ReactNode } from '@tanstack/react-router';
import { Flex, Space, Typography } from 'antd';

export const PageLayout: FC<PropsWithChildren<{ title: string; extra?: ReactNode; breadcrumbs?: ReactNode }>> = ({
  children,
  title,
  breadcrumbs,
  extra,
}) => {
  return (
    <>
      <Flex vertical={false} justify="space-between" align="center">
        <Typography.Title level={2}>{title}</Typography.Title>
        {extra}
      </Flex>
      {breadcrumbs ? <Space>{breadcrumbs}</Space> : null}
      {children}
    </>
  );
};
