import { useState } from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { Button } from 'antd';
import { boolean, fallback, literal, number, object, optional, parse, string, union } from 'valibot';

import { PageLayout } from '@/components';

import { GiftModal } from './-components/GiftModal';
import { PurchasesTable, type PurchasesTableFilters } from './-components/PurchasesTable';

const SearchSchema = object({
  limit: fallback(optional(number(), 50), 50),
  skip: fallback(optional(number(), 0), 0),
  deleted: fallback(optional(boolean()), undefined),
  canceled: fallback(optional(boolean()), undefined),
  productId: fallback(optional(string()), undefined),
  purchaseType: fallback(
    optional(
      union([literal('gift'), literal('bluesnap'), literal('stripe'), literal('app_store'), literal('google_play')]),
    ),
    undefined,
  ),
  accountEmail: fallback(optional(string()), undefined),
});

export const Route = createFileRoute('/_authenticated/billing/purchases/')({
  component: Purchases,
  validateSearch: (search: Record<string, unknown>): PurchasesTableFilters => parse(SearchSchema, search),
});

function Purchases() {
  const [isGiftModalOpened, setIsGiftModalOpened] = useState(false);
  const searchParams = Route.useSearch();
  const navigate = Route.useNavigate();

  return (
    <PageLayout
      title="Purchases"
      extra={
        <Button type="primary" onClick={() => setIsGiftModalOpened(true)}>
          Add a gift
        </Button>
      }
    >
      <PurchasesTable
        filters={searchParams}
        onFiltersChange={(filters) => {
          navigate({
            search: filters,
          });
        }}
      />
      <GiftModal
        isOpened={isGiftModalOpened}
        onClose={() => setIsGiftModalOpened(false)}
        email={searchParams.accountEmail}
      />
    </PageLayout>
  );
}
