import { FC, useState } from 'react';
import { Form, Input, Modal, Select } from 'antd';

import { useAddGift, useProducts } from '@/features/billing';

type GiftModalProps = {
  isOpened: boolean;
  onClose: () => void;
  email: string | undefined;
};

type GiftFormValues = {
  accountEmail: string;
  productId: string;
  expirationDate: string;
};

const FormItem = Form.Item<GiftFormValues>;

export const GiftModal: FC<GiftModalProps> = ({ isOpened, onClose, email }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const products = useProducts();
  const addGift = useAddGift();

  return (
    <Modal
      title="Gift a plan"
      open={isOpened}
      onClose={onClose}
      onCancel={onClose}
      okButtonProps={{
        htmlType: 'submit',
      }}
      destroyOnClose
      modalRender={(dom) => (
        <Form<GiftFormValues>
          name="gift-form"
          labelCol={{ span: 6 }}
          disabled={isProcessing}
          clearOnDestroy
          onFinish={async (values) => {
            try {
              setIsProcessing(true);
              await addGift.mutateAsync(values);
              onClose();
            } finally {
              setIsProcessing(false);
            }
          }}
        >
          {dom}
        </Form>
      )}
    >
      <FormItem name="accountEmail" label="Account email" rules={[{ required: true }]} initialValue={email}>
        <Input type="email" />
      </FormItem>
      <FormItem name="productId" label="Product" rules={[{ required: true }]}>
        <Select disabled={products.isLoading}>
          {products.data?.map((product) => (
            <Select.Option key={product.id} value={product.label}>
              {product.label}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      <FormItem name="expirationDate" label="Expires at" rules={[{ required: true }]}>
        <Input type="date" />
      </FormItem>
    </Modal>
  );
};
