import { useQuery } from '@tanstack/react-query';

import { ApiUrl, httpClient } from '@/libs';

import { getEnrichmentWrongReportQueryKey } from '../keys.ts';
import { EnrichmentWrongReport } from '../types.ts';

type EnrichmentWrongInfoReportsResult = {
  data: EnrichmentWrongReport[];
  length: number;
};

export const useEnrichmentWrongInfoReports = (params: Record<string, unknown>) => {
  return useQuery<EnrichmentWrongInfoReportsResult, Error>({
    queryKey: getEnrichmentWrongReportQueryKey(params),
    queryFn: async () => {
      const response = await httpClient.get<EnrichmentWrongInfoReportsResult>(
        ApiUrl.getEnrichmentUrl('/report/wrong-info'),
        {
          params,
        },
      );

      return response.data;
    },
  });
};
