import { useState } from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { App, type MenuProps, Modal } from 'antd';

import { ProductType, useDeleteProductType } from '@/features/billing';

export const useProductTypeActions = (params: { productType: ProductType }) => {
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const { message } = App.useApp();
  const deleteProductType = useDeleteProductType();

  const items: MenuProps['items'] = [
    {
      key: 'edit',
      label: 'Edit',
      onClick: () => {
        setIsEditModalOpened(true);
      },
    },
    {
      key: 'delete',
      label: 'Delete',
      danger: true,
      onClick: async () => {
        Modal.confirm({
          title: `Delete product type "${params.productType.label}"?`,
          icon: <ExclamationCircleFilled />,
          onOk: async () => {
            try {
              await deleteProductType.mutateAsync(params.productType.id);
            } catch (e) {
              message.error(`Failed to delete product type "${params.productType.label}"`);
            }
          },
        });
      },
    },
  ];

  const handleEditModalClose = () => {
    setIsEditModalOpened(false);
  };

  return {
    isEditModalOpened,
    handleEditModalClose,
    items,
  };
};
