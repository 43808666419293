import { FC, PropsWithChildren, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Layout, Spin } from 'antd';

import { AppHeader, AppSider } from './components';

import styles from './styles.module.scss';

type Props = {
  userEmail: string;
  onLogout(): void;
};

export const AppLayout: FC<PropsWithChildren<Props>> = ({ children, ...props }) => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const client = useQueryClient();

  const handleRefresh = async (): Promise<void> => {
    try {
      setIsRefreshing(true);
      await client.refetchQueries({
        type: 'active',
      });
    } finally {
      setIsRefreshing(false);
    }
  };

  return (
    <Layout className={styles.layout}>
      <AppHeader {...props} onRefresh={handleRefresh} isRefreshing={isRefreshing} />
      <Layout hasSider>
        <AppSider />
        <Layout.Content className={styles.content}>
          <Spin spinning={isRefreshing} size="large" tip="Refreshing...">
            {children}
          </Spin>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};
