import dayjs from 'dayjs';

import { ApiUrl, FileHelper, httpClient } from '@/libs';

export const usePowerleadDownloadEnrichmentResult = () => {
  return async (uuid: string) => {
    const response = await httpClient.get<string>(
      ApiUrl.getPowerleadUrl('/csv-enrichment-admin/:uuid').replace(':uuid', uuid),
      {
        headers: {
          'Content-Type': 'text/plain',
        },
        params: {
          download: true,
        },
      },
    );
    FileHelper.downloadCSV(response.data, `file-${dayjs().format('YYYY-MM-DD')}.csv`);
  };
};
