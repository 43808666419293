import type { CreditType } from '../types';

const creditTypes: ReadonlyArray<CreditType> = Object.freeze([
  'basic',
  'note',
  'company',
  'price_proposal',
  'scheduler',
  'task',
  'tag_link',
  'full',
]);

export const useCreditTypes = () => creditTypes;
