import { useProductTypes } from '@/features/billing';

export const useProductTypesTableViewModel = () => {
  const { data: productTypes, isLoading, isError } = useProductTypes();

  return {
    dataSource: productTypes,
    isLoading,
    isError,
  };
};
